import { Injectable } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { ResponseEntity } from '../model/response';
import { environment } from '../../environments/environment';
import { TextSection, GeneSection, Version } from '../model/section/version';
import { ReplaySubject } from 'rxjs';


@Injectable()
export class SectionService {

	url: string;

	private dataObs$: ReplaySubject<any>;

  constructor(private http: HttpClient) {
  	this.url = environment.apiUrl;
  	this.dataObs$ = new ReplaySubject(1);
	}

	getAllVersion() {
		return this.http.get<ResponseEntity>(this.url + '/api/versions');
	}

	getMaxVersion() {
 		if (!this.dataObs$.observers.length ) {
        	this.http.get<ResponseEntity>(this.url + '/api/versions/max').subscribe(
            	data => this.dataObs$.next(data),
            	error => {
                	this.dataObs$.error(error);
                	this.dataObs$ = new ReplaySubject(1);
	            }
    	    );
    	}

    return this.dataObs$;
	}


	getVersion(id: number) {
		return this.http.get<ResponseEntity>(this.url + '/api/versions/' + id);
	}

	publishVersion(id: number) {
		return this.http.get<ResponseEntity>(this.url + '/api/versions/publish/' + id);
	}

	checkVersion(id: number) {
		return this.http.get<ResponseEntity>(this.url + '/api/versions/test/' + id);
	}

	getSection(id: number) {
		return this.http.get<ResponseEntity>(this.url + '/api/sections/' + id);
	}

	updateTextSection(section: TextSection) {
		const id = section.id;
		const sectionCopy: any = Object.assign({}, section);
		delete sectionCopy.id;
		delete sectionCopy.childrenId;
		delete sectionCopy.parentId;
		delete sectionCopy.versionId;
		console.log(sectionCopy);
		return this.http.patch<ResponseEntity>(this.url + '/api/sections/' + id, sectionCopy);
	}

	addTextSection(section: TextSection, parentId: number, parentType: string) {
		const sectionCopy: any = Object.assign({}, section);
		delete sectionCopy.id;
		delete sectionCopy.childrenId;
		delete sectionCopy.parentId;
		delete sectionCopy.versionId;
		return this.http.post<ResponseEntity>(this.url + '/api/' + parentType + 's/' + parentId + '/sections', sectionCopy);
	}

	createVersion(isMajor: boolean, version: Version) {
	    version.sections = [];
		if (isMajor) {
			return this.http.post<ResponseEntity>(this.url + '/api/versions/major', version);
		} else {
			return this.http.post<ResponseEntity>(this.url + '/api/versions/minor', version);
		}
	}


	updateGeneSection(section: GeneSection) {
		const id = section.id;
		const sectionCopy: any = Object.assign({}, section);
		delete sectionCopy.id;
		delete sectionCopy.parentId;
		delete sectionCopy.versionId;
		return this.http.patch<ResponseEntity>(this.url + '/api/sections/' + id, sectionCopy);
	}

	addGeneSection(section: GeneSection, parentId: number, parentType: string) {
		const sectionCopy: any = Object.assign({}, section);
		delete sectionCopy.id;
		delete sectionCopy.parentId;
		delete sectionCopy.versionId;
		return this.http.post<ResponseEntity>(this.url + '/api/' + parentType + 's/' + parentId + '/sections', sectionCopy);
	}

	 sendMail(msg: string) {
    return this.http.post<ResponseEntity>(this.url + '/api/test/mail', msg);
  }

  	addMsg(msg: string, version: number) {
  		return this.http.post<ResponseEntity>(this.url + '/api/versions/msg/' + version, msg);
  	}
}
