import { Component, OnInit } from '@angular/core';
import { SectionService } from '../../services/section.service';
import { Version} from '../../model/section/version';
import { Router } from '@angular/router';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit {

  versions:Version[];

  constructor(private sectionService:SectionService, private router:Router) { }

  ngOnInit() {
  	this.sectionService.getAllVersion().subscribe(
      data => {
        if(data.result.length == 1) {
          this.router.navigate(['/version',data.result[0].id]);
        }
        this.versions = data.result
      });
  }

  openVersion() {
  	
  }
}
