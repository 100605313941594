import {NgModule} from '@angular/core';
import {
 // MatAutocompleteModule,
  MatButtonModule,
 // MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
 // MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
 // MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
 // MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
 // MatSortModule,
 // MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
 // MatStepperModule,
} from '@angular/material';
import {MatNativeDateModule, MatRippleModule} from '@angular/material';
//import {CdkTableModule} from '@angular/cdk/table';
//import {CdkAccordionModule} from '@angular/cdk/accordion';
//import {A11yModule} from '@angular/cdk/a11y';
//import {BidiModule} from '@angular/cdk/bidi';
//import {OverlayModule} from '@angular/cdk/overlay';
//import {PlatformModule} from '@angular/cdk/platform';
//import {ObserversModule} from '@angular/cdk/observers';
//import {PortalModule} from '@angular/cdk/portal';

/**
 * NgModule that includes all Material modules that are required to serve the demo-app.
 */
@NgModule({
  exports: [
  //  MatAutocompleteModule,
    MatButtonModule,
  //  MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
  //  MatTableModule,
    MatDividerModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
  //  MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
  //  MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  //  MatRadioModule,
  //  MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
  //  MatSortModule,
  //  MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
  //  CdkTableModule,
  //  A11yModule,
  //  BidiModule,
  //  CdkAccordionModule,
  //  ObserversModule,
  //  OverlayModule,
  //  PlatformModule,
  //  PortalModule,
  ]
})
export class NgMaterialModule { }