import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { GeneSection, SubSection, Variant,Snp} from '../model/section/version';
import { requiredLangValidator } from '../directive/required-lang-directive';

@Injectable()
export class GeneSectionFormBuilderService {



  constructor(private fb:FormBuilder) { }

  public removeSubSection(fg:FormGroup,index:number) {
  	(fg.controls['subsections'] as FormArray).removeAt(index);
  }

  public addSubSection(fg:FormGroup) {
  	(fg.controls['subsections'] as FormArray).push(this.createSubSectionGroup());
  }

 public removeSnp(fg:FormGroup,index:number) {
  	(fg.controls['snps'] as FormArray).removeAt(index);
  }

  public addSnp(fg:FormGroup) {
  	(fg.controls['snps'] as FormArray).push(this.createSnpGroup());
  }

   public removeSnpValue(fg:FormGroup,index:number) {
    (fg.controls['snpValues'] as FormArray).removeAt(index);
  }

  public addSnpValue(fg:FormGroup) {
    (fg.controls['snpValues'] as FormArray).push(this.createSnpValuesGroup());
  }

   public removeVariantTab(fg:FormGroup,index:number) {
  	(fg.controls['variants'] as FormArray).removeAt(index);
  }

  public addVariantTab(fg:FormGroup) {
  	(fg.controls['variants'] as FormArray).push(this.createVariantGroup());
  }

  public createForm():FormGroup {
  	return this.fb.group({
      title:[''  , requiredLangValidator()],
      priority:['' , Validators.compose([Validators.required,Validators.max(100), Validators.min(0)])],
      labelLeft:[''  ,requiredLangValidator()],
      labelRight:[''  ,requiredLangValidator()],
      indicator:[''  ,Validators.required],
      raportEnabled: [''],
      modules: [],
      backgroundImage:[''],
      scale:[''  ,Validators.required],
      contents: this.fb.group({
              header:['', requiredLangValidator()],
              funFacts:[''],
              footer:['']
      }),
      subsections: this.fb.array([this.createSubSectionGroup()])
    });

  }



  public populateForm(section:GeneSection, fg:FormGroup) {
    fg.controls['modules'].setValue(section && section.modules);
  	fg.controls['title'].setValue(section &&section.title ? section.title : '');
  	fg.controls['priority'].setValue(section && section.priority ? section.priority : '');
    if(section) {
      fg.controls['raportEnabled'].setValue(section.raportEnabled);
    } else {
      fg.controls['raportEnabled'].setValue('true');
    }
  	(fg.controls['contents'] as FormGroup).controls['header'].setValue(section && section.header  ? section.header :'');
  	(fg.controls['contents'] as FormGroup).controls['footer'].setValue(section && section.footer ? section.footer :'');
  	(fg.controls['contents'] as FormGroup).controls['funFacts'].setValue(section && section.funFacts ? section.funFacts :'');
    fg.controls['labelLeft'].setValue(section && section.labelLeft  ? section.labelLeft : '');
    fg.controls['labelRight'].setValue(section && section.labelRight  ? section.labelRight : '');
    fg.controls['backgroundImage'].setValue(section && section.backgroundImage && section.backgroundImage ? section.backgroundImage : '');
    
    fg.controls['indicator'].setValue(section && section.indicator ? section.indicator : '');
    fg.controls['scale'].setValue(section && section.scale ? section.scale : '');
  	let subsections:FormArray = fg.controls['subsections'] as FormArray;
  	if(section && section.subsections) {
  		section.subsections.forEach((subsection, index) => {
    		if(!subsections.at(index)) {
    			subsections.insert(index,this.createSubSectionGroup());
    		}
    		this.populateSubSectionPart(subsection,subsections.at(index) as FormGroup);    		
		});
  	} 
  }

 
  private populateSubSectionPart(subSection:SubSection,fg:FormGroup) {
  	fg.controls['name'].setValue(subSection.name);
  	fg.controls['chromosome'].setValue(subSection.chromosome);
  	fg.controls['study'].setValue(subSection.study);
  	fg.controls['gene'].setValue(subSection.gene);
   

  	let snps:FormArray = fg.controls['snps'] as FormArray;
  	if(subSection.snps) {
  		subSection.snps.forEach((snp,index) => {
  			if(!snps.at(index)) {
  				snps.insert(index,this.createSnpGroup());
  			}
  			let snpFG:FormGroup = snps.at(index) as FormGroup;
  			snpFG.controls['snpValue'].setValue(snp.snpValue);
  			snpFG.controls['correctValues'].setValue(snp.correctValues);
  		});
  	}
  	let variants:FormArray = fg.controls['variants'] as FormArray;
  	if(subSection.variants) {
  		subSection.variants.forEach((variant,index) => {
  			if(!variants.at(index)) {
  				variants.insert(index,this.createVariantGroup());
  			}
  			this.populateVariantPart(variant,variants.at(index) as FormGroup);
  		});
  	}
  }


	private populateVariantPart(variant:Variant,fg:FormGroup) {
		fg.controls['name'].setValue(variant.name);
		fg.controls['description'].setValue(variant.description);
		fg.controls['probability'].setValue(Math.round(variant.probability*100)/100);
    fg.controls['score'].setValue(Math.round(variant.score*100)/100);
    fg.controls['shortDescription'].setValue(variant.shortDescription ? variant.shortDescription : '');
		let snpsValueFA:FormArray = fg.controls['snpValues'] as FormArray;
		if(variant.snpValues) {
			variant.snpValues.forEach((snpValue,index) => {
				if(!snpsValueFA.at(index)) {
					snpsValueFA.insert(index,this.createSnpValuesGroup());
				}
				(snpsValueFA.at(index) as FormGroup).controls['value'].setValue(snpValue);
			});
		}
	}



  private createSubSectionGroup(){
    return this.fb.group({
        name: ['',Validators.required],
        chromosome: ['',Validators.required],
        study: [''],
        gene: ['',Validators.required],
        snps: this.fb.array([this.createSnpGroup()]),
        variants: this.fb.array([this.createVariantGroup()])
      });
  }

  private createVariantGroup():FormGroup{
    return this.fb.group({
          name:['',Validators.required],
          probability:['',Validators.required],
          description:['',requiredLangValidator()],
          shortDescription:[''],
          score:[''],
          snpValues: this.fb.array([this.createSnpValuesGroup()])
        });
  }


  private createSnpValuesGroup():FormGroup{
    return this.fb.group({
            value: ['',Validators.required]
          });

  }

  private createSnpGroup():FormGroup{
    return this.fb.group({
          snpValue:['',Validators.required],
          correctValues:['',Validators.required]
        });
  }

  public getGeneSection(fg:FormGroup,geneSection:GeneSection):GeneSection {
    let geneSectionCopy = fg.value;
    geneSection.type = 'Gene';
    geneSection.title = new Object();
    geneSection.header = new Object();
    geneSection.labelLeft =  new Object();
    geneSection.labelRight =  new Object();
    geneSection.raportEnabled = geneSectionCopy.raportEnabled;
    geneSection.modules = geneSectionCopy.modules;
    geneSection.labelLeft = geneSectionCopy.labelLeft;
    geneSection.backgroundImage = geneSectionCopy.backgroundImage;
    geneSection.labelRight = geneSectionCopy.labelRight;
    geneSection.header = geneSectionCopy.contents.header;
    if(geneSectionCopy.contents.funFacts) {
      geneSection.funFacts =  new Object();
      geneSection.funFacts = geneSectionCopy.contents.funFacts;
    } else {
      geneSection.funFacts = null;
    }
    if(geneSectionCopy.contents.footer) {
      geneSection.footer =  new Object();
      geneSection.footer = geneSectionCopy.contents.footer;
    } else {
      geneSection.footer = null;
    }
   
    geneSection.title = geneSectionCopy.title;
    geneSection.indicator = geneSectionCopy.indicator;
    geneSection.priority = geneSectionCopy.priority;
    geneSection.scale = geneSectionCopy.scale;
    geneSection.subsections = new Array<SubSection>();
    
    geneSectionCopy.subsections.forEach((subsectionCopy) => {
      let subsection = new SubSection();
      subsection.chromosome = subsectionCopy.chromosome;
      subsection.gene = subsectionCopy.gene;
      subsection.study =subsectionCopy.study;
      subsection.name = subsectionCopy.name;
      subsection.snps = new Array<Snp>();
      subsectionCopy.snps.forEach((snpCopy) =>{
        let snp = new Snp();
        snp.correctValues = snpCopy.correctValues;
        snp.snpValue = snpCopy.snpValue;
        subsection.snps.push(snp);
      });
      subsection.variants = new Array<Variant>();
      subsectionCopy.variants.forEach((variantCopy)=>{
        let variant = new Variant();
        variant.description = new Object();
        variant.shortDescription = new Object();
        variant.description = variantCopy.description;
        variant.shortDescription = variantCopy.shortDescription;
        variant.score = variantCopy.score;
        variant.probability = variantCopy.probability;
        variant.name = variantCopy.name;
        variant.snpValues = new Array<string>();
        variantCopy.snpValues.forEach((valueCopy) => {variant.snpValues.push(valueCopy.value)});
        subsection.variants.push(variant);
      });
      geneSection.subsections.push(subsection);
    });

    return geneSection;
  }

}
