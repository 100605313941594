
 <mat-card>
    <!--mat-card-subtitle>Wersja nr: {{version?.major}}.{{version?.minor}}</mat-card-subtitle-->
    <!--mat-card-title>Stan: {{version?.state}}</mat-card-title-->
    <mat-card-content>
      <span fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="start start" >
      <span fxFlex.gt-sm="50" fxLayout="column" fxLayoutAlign="start start">
        <span class="mat-display-1">Wersja: {{version?.major}}.{{version?.minor}} - {{version?.state}}</span>
        <span>Stworzono: <b>{{version?.created}}</b> przez: <b>{{version?.createdBy}}</b></span>
        <span fxFlexOffset="2">Ostatnia modyfikacja: <b>{{version?.modified}}</b> przez: <b>{{version?.modifiedBy}}</b></span>
      </span>
      <span fxFlex.gt-sm="50">
        <mail-template [msg]="version?.emailMsg" [version] = "version?.id" (onSaved)="refresh($event)"></mail-template>
      </span>
    </span>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
      <ogen-download isButtonTemplate="true" title="Generuj" [matTooltip]="'Zostanie wygenerowany raport dla wersji: ' + version?.major + '.' + version?.minor" [version]="version?.id" url="/api/report/dummy"></ogen-download>
      <button *ngIf="version?.state=='DRAFT'" mat-raised-button color="primary" (click)="publish()">Publikuj</button>
      <button *ngIf="version?.state=='PUBLISHED'" mat-raised-button color="primary" (click)="createVersion(true)">Stwórz draft GŁÓWNY: ({{version?.major + 1}}.0)</button>
      <button *ngIf="version?.state=='PUBLISHED'" mat-raised-button color="primary" (click)="createVersion(false)">Stwórz draft POPRAWKOWY: ({{version?.major}}.{{version?.minor +1}})</button>
      <button *ngIf="version?.state=='DRAFT'" mat-raised-button color="primary" [routerLink]="['/app/version',version?.id,'section']">Dodaj sekcje z genami</button>
      <button *ngIf="version?.state=='DRAFT'" mat-raised-button color="primary" [routerLink]="['/app/version',version?.id,'paragraph']">Dodaj rozdział</button>
      <button mat-stroked-button color="accent" matTooltip="Sprawdza czy dla tych samych RS`ow nie ma roznego prawdopodobienstwa" (click)="checkVersion()">Sprawdź wersję</button>

    </mat-card-actions>
  
  </mat-card>

<mat-nav-list *ngIf="version?.sections">
  <h3 mat-subheader>Sekcje</h3>
    <div *ngFor="let section of version?.sections">
      <mat-list-item class="list-item">
        <a [routerLink]="section.type == 'Text' ? ['/app/paragraph',section.id,'view'] : ['/app/section',section.id,'edit']" >
             <mat-icon [color]="section.type == 'Text' ? 'primary' : 'warn'">{{calculateIcon(section)}}</mat-icon></a>
             <a [class.strike-through] = "!section.enabled" fxFlexOffset="1"  [routerLink]="section.type == 'Text' ? ['/app/paragraph',section.id,'view'] : ['/app/section',section.id,'edit']" >
              <span>{{section.name}}</span> <span style="margin-left: 2vw ;color: darkgreen;">Typ: {{section.type}} </span> <span style="margin-left: 2vw ;color: fuchsia;font-style: italic;" *ngIf="section.modules">  Moduły: {{section.modules}}</span>
            </a>
        
        <span style="flex: 1 1 auto;"></span>
        <section class="margin-right" >
          <button [routerLink]="section.type == 'Text' ? ['/app/paragraph', section.id,'view'] : ['/app/section', section.id,'view']" mat-mini-fab color="primary" >
              <mat-icon>visibility</mat-icon>
          </button>
          <button  [routerLink]="section.type == 'Text' ? ['/app/paragraph', section.id,'edit'] : ['/app/section', section.id,'edit']" fxFlexOffset="20" mat-mini-fab color="primary"  >
            <mat-icon>edit</mat-icon>
          </button>
        </section>
      </mat-list-item>
      <mat-divider></mat-divider>
  </div>
</mat-nav-list>
