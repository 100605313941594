import { Directive,Input,TemplateRef,ViewContainerRef,OnInit } from '@angular/core';
import { AuthGuard } from '../guard/auth.guard'

@Directive({
  selector: '[hasRole]'
})
export class UserCanAccessDirective implements OnInit {

 	@Input('hasRole') role: string;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private authGuard:AuthGuard
              ) {}


   ngOnInit() {
  		if(this.authGuard.hasRole(this.role)) {
  			this.viewContainer.createEmbeddedView(this.templateRef);
  		} else {
			this.viewContainer.clear();
  		}
  }

}
