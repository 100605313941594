import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseEntity } from '../model/response';
import { OrderEvent } from '../model/order';
import { OrderItemsVersion, VersionItem} from '../model/order-items-version.ui';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Module } from '../model/module';

@Injectable()
export class OrderService {


	private orderUrl:string;

  constructor(private _http:HttpClient) {
  	this.orderUrl = environment.apiUrl; 
	}


  saveOrderModules(order:string, modules: Module[]) {
    return this._http.post<ResponseEntity>(this.orderUrl + '/api/barcode/'+order + '/modules',modules);
  }

  getOrders() {
  	return this._http.get<ResponseEntity>(this.orderUrl + '/api/barcode');
  }


  searchOrdersFull(searchFraze:string) {
  	return this._http.get<ResponseEntity>(this.orderUrl + '/api/fullsearch/orders/' + searchFraze);
  }

  searchOrders(searchFraze:string) {
  	return this._http.get<ResponseEntity>(this.orderUrl + '/api/search/orders/' + searchFraze);
  }

  getOrderEvents(orderCode:string) {
    return this._http.get<ResponseEntity>(this.orderUrl+'/api/order/' + orderCode + '/events/')
  }

  regenerate(orderCode:string) {
    return this._http.get<ResponseEntity>(this.orderUrl+'/api/order/update/' + orderCode)
  }

  regenerateBatch(orderCodes:string[]) {
    return this._http.post<ResponseEntity>(this.orderUrl+'/api/order/update/',orderCodes);
  }


  enable(orderCode:string) {
    return this._http.get<ResponseEntity>(this.orderUrl+'/api/order/enableUpdate/' + orderCode)
  }

  getOrdersForUpdate() {
    return this._http.get<ResponseEntity>(this.orderUrl+'/api/order/update/')
  }

  disable(orderCode:string) {
    return this._http.get<ResponseEntity>(this.orderUrl+'/api/order/disableUpdate/' + orderCode)
  }
 
  saveEvent(orderCode:string,orderEvent:OrderEvent) {
    return this._http.post<ResponseEntity>(this.orderUrl + '/api/order/' +  orderCode +'/events/',
      {type: orderEvent.type,
        eventDate: orderEvent.eventDate.getDate().toLocaleString(undefined,{minimumIntegerDigits:2,useGrouping:false}) +'-' + (orderEvent.eventDate.getMonth()+1).toLocaleString(undefined,{minimumIntegerDigits:2,useGrouping:false}) +'-' + orderEvent.eventDate.getFullYear() + ' 00:00:00'});

  }

  orderItemsVersion(orderCode:string) {

      let response =  this._http.get<ResponseEntity>(this.orderUrl+ '/api/order/' + orderCode + '/versions');
      return response.pipe(map(t => {
      let orderItemsVersion = new OrderItemsVersion();
      orderItemsVersion.infographic=new VersionItem(t.result.infographic);
      orderItemsVersion.rawData=new VersionItem(t.result.rawData);
      orderItemsVersion.report=new VersionItem(t.result.report); 
      orderItemsVersion.availableVersions = t.result.availableVersions.map(v => new VersionItem(v));
      t.result = orderItemsVersion;
      return t;
      }));
     
      /*let responseEntity = <ResponseEntity>{};
      let orderItemsVersion = new OrderItemsVersion();
      orderItemsVersion.infographic=new VersionItem('1.10');
      orderItemsVersion.rawData=new VersionItem('1.10');
      orderItemsVersion.report=new VersionItem('1.12');
      orderItemsVersion.availableVersions=[
      new VersionItem('1.0'),
      new VersionItem('1.1'),
      new VersionItem('1.2'),
      new VersionItem('1.3'),
      new VersionItem('1.4'),
      new VersionItem('1.5'),
      new VersionItem('1.6'),
      new VersionItem('1.7'),
      new VersionItem('1.8'),
      new VersionItem('1.9'),
      new VersionItem('1.10'),
      new VersionItem('1.11'),
      new VersionItem('1.12'),
      new VersionItem('1.13'),
      new VersionItem('1.14'),
      new VersionItem('1.15')
      ];
      responseEntity.success = true;
      responseEntity.result = orderItemsVersion;
      return from([responseEntity]).pipe(delay(1000));*/
  }

  updateVersionItem(orderCode:string,updateItem) {
       return  this._http.post<ResponseEntity>(this.orderUrl+ '/api/order/' + orderCode + '/versions',updateItem);
   
  }
}
