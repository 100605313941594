import { Component, OnInit } from '@angular/core';
import { OrderService} from '../../services/order.service';
import { DownloadService } from '../../services/download.service';
import { Order,OrderEvent } from '../../model/order';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';
import { OrderUpdateComponent } from '../order-update/order-update.component';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

   orders:Order[];
   order:Order;
   searchType:boolean;
   searchValue:string;
   config = {
    disableClose: false,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '',
    height: '',
    minWidth: '',
    minHeight: '',
    maxWidth: '1600px',
    maxHeight: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      order: {},
    }
  };
  private dialogRef:MatDialogRef<OrderUpdateComponent>;
  

  constructor(public dialog: MatDialog,private orderService:OrderService,private downloadService:DownloadService,public snackBar: MatSnackBar) {
   }

  ngOnInit() {
  	this.orderService.getOrders().subscribe(data => {this.orders = data.result;});

  }

  openDialog(orderCode:Order) {
    this.config.data.order = orderCode; 
    this.dialogRef = this.dialog.open(OrderUpdateComponent, this.config);


  }

  onOrderChange(order:Order) {
    console.log('OnOrderChange');
    let index = this.orders.findIndex(t => t.barcode_value == order.barcode_value);
    this.orders.splice(index,1,order);
    console.log(order);
  }
  
  search() {
      let type = this.searchType ? false:true;
      let value = this.searchValue ? this.searchValue : '';
      
    if(value.length <4) {
      return;
    }
    else {
       this.searchType ?
       this.orderService.searchOrders(value).subscribe(data => this.orders = data.result):
       this.orderService.searchOrdersFull(value).subscribe(data => this.orders = data.result);
    }

  }

  enable(item:Order) {
    this.orderService.enable(item.barcode_value).subscribe(data =>{this.show("Włączono aktualizacje dla kodu " + item.barcode_value); this.refresh();},error =>  {console.log(error);this.show("Nastapil blad");});
   

  }

  disable(item:Order) {
    this.orderService.disable(item.barcode_value).subscribe(data =>{this.show("Wyłączono aktualizacje dla kodu " + item.barcode_value); this.refresh();},error =>  {console.log(error);this.show("Nastapil blad");});
   
  }


  onSelectOrder(item:Order) {
    this.order = item;
  }

  checkReceive(item:Order) {
    if(item.events) {
      for (let o of item.events) {
        if(o.type === 'EXAMPLE_RECEIVE') {
          return true;
        }
      }

    }
    return false;
  }

checkSend(item:Order) {
    if(item.events) {
     // for(item.events.forEach(o => {if(o.type === 'EXAMPLE_SEND_TO_LAB') return false;});
       for (let o of item.events) {
        if(o.type === 'EXAMPLE_SEND_TO_LAB') {
          return true;
        }
      }
    }
    return false;
  }

  isGenerated(item:Order) {
    //console.log(item);
    return !this.checkResult(item);
}

  checkResult(item:Order) :boolean {
    if(item.events) {
     // for(item.events.forEach(o => {if(o.type === 'EXAMPLE_SEND_TO_LAB') return false;});
       for (let o of item.events) {
        if(o.type === 'RESULT_RECEIVE_FROM_LAB') {
          return true;
        }
      }
    }
    return false;
  }

  regenerate(item:Order) {
    this.orderService.regenerate(item.barcode_value).subscribe(data =>this.show("Ok"),error =>  {console.log(error);this.show("Nastapil blad");});
  }

  show(msg:string) {
   this.snackBar.open(msg,'',{duration: 3000})
  }

  saveResult(item:Order){
    
    let oe:OrderEvent = new OrderEvent();
    oe.type = 'RESULT_RECEIVE_FROM_LAB';
    oe.eventDate = new Date();   
    this.orderService.saveEvent(item.barcode_value,oe).subscribe(data => this.refresh());
  }

  saveReceive(item:Order){
    
    let oe:OrderEvent = new OrderEvent();
    oe.type = 'EXAMPLE_RECEIVE';
    oe.eventDate = new Date();   
    this.orderService.saveEvent(item.barcode_value,oe).subscribe(data => this.refresh());
  }

  saveSend(item:Order) {
    
    let oe:OrderEvent= new OrderEvent();
    oe.type = 'EXAMPLE_SEND_TO_LAB';
    oe.eventDate = new Date();   
    this.orderService.saveEvent(item.barcode_value,oe).subscribe(data => this.refresh());

  }

  refresh() {
    if(this.searchValue && this.searchValue.length >=4) {
      this.search();
    } else {
      this.orderService.getOrders().subscribe(data => this.orders = data.result);
    }


  }

}
