<h3>
  Zamówienia
</h3>

<div fxLayout="row" fxLayoutAlign="space-between">
    <mat-form-field>
        <input matInput  matTooltip="Wpisz 4 znaki nr zamówienia lub części adresu delikwenta" matTooltipPosition="above" type="text" placeholder="Szukana fraza" [(ngModel)]="searchValue"  (ngModelChange)="search()"/>
        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-slide-toggle [(ngModel)]="searchType" (ngModelChange)="search()" matTooltip="Zaznaczenie powoduje wyszukiwanie tylko po numerze zamówienia">Uwzględniaj niezajęte</mat-slide-toggle>
 
</div>



 <mat-nav-list>
    <div *ngFor="let order of orders; let last=last">
      <mat-list-item>
      <mat-icon *ngIf="!order.updatable" color="warn" matTooltip="Bez upgrade`u">warning</mat-icon>
        <a (click)="onSelectOrder(order)">
            <h5 mat-line> {{ order.barcode_value }}
                <i *ngFor="let module of order.modules, let first = first"><span *ngIf='!first'>,</span> {{module.name}}</i>               
                
              
            </h5>
            <p mat-line *ngIf="order.user"> {{'Użytkownik: ' + order.user.email + ' ' + order.user.name + ' ' + order.user.surname + ' Tel: ' + order.user.phoneNumber  }} </p>
            <p *ngIf="order.sampleAddress" mat-line class="third-line"> {{'Adres z kuriera: '  + order.sampleAddress.street + ' ' + order.sampleAddress.flatNumber + ', ' + order.sampleAddress.zipCode + ' ' + order.sampleAddress.city }} </p> 
        </a>
        <span style="flex: 1 1 auto;"></span>
        <section class="margin-right" >
<div fxLayout="column"fxLayoutAlign="space-between start" fxLayoutGap="10px">
        <div fxLayout = "row" fxLayoutGap="10px">
          <button *ngIf="!order.updatable" class="mat-small" (click)="enable(order)" matTooltip="Aktywacja aktualizacji raportu" mat-flat-button color="primary">Aktywuj</button>
          <button *ngIf="order.updatable" class="mat-small" (click)="disable(order)" matTooltip="Dezaktywacja aktualizacji raportu" mat-flat-button color="warn">Dezaktywuj</button>
        

        <button mat-flat-button  [disabled]="isGenerated(order)" *hasRole="'ROLE_UPDATE'" matTooltip="Aktualizacja raportu lub infografiki do nowej wersji,  wiąże się z wygenerowaniem nowego hasła. Używać z rozwagą."
         color="primary" (click)="openDialog(order)" class="mat-small">Aktualizuj</button>
      </div>
      <div fxLayout = "row" fxLayoutGap="10px">          
        <ogen-download  [disabled]="order.reportSendItem && isGenerated(order)" 
                matTooltip="Pacnij jak chce Ci się chce ściągnąć zip z 3 infografikami" 
                color="accent" 
                title="3 infografiki"
                color="primary"
                class="mat-small" 
                isButtonTemplate="true"
                [url]="'/api/order/infographic/printVersions/' + order.barcode_value">
          
          </ogen-download>
        <ogen-download  [disabled]="isGenerated(order)" 
                matTooltip="Pobierz raport do druku" 
                color="accent" 
                title="Raport do druku"
                color="primary"
                class="mat-small" 
                isButtonTemplate="true"
                [url]="'/api/report/' + order.barcode_value">
          
          </ogen-download></div>
      <div fxLayout = "row" fxLayoutGap="10px">
        <button [disabled]="checkReceive(order)" class="mat-small" matTooltip="Pacnij jeśli otrzymaliśmy jego ślinę"  mat-flat-button color="green" (click)="saveReceive(order)">
              Dotarł kurier z paczką
          </button>
          <button [disabled]="checkSend(order)" class="mat-small" matTooltip="Pacnij jak wysłaliśmy do labu"  mat-flat-button color="green"  (click)="saveSend(order)">
            Wysłalismy do lab.
          </button>
           <button [disabled]="checkResult(order)" class="mat-small" matTooltip="Pacnij jak otrzymalismy wynik z laboratorium" mat-flat-button color="green"  (click)="saveResult(order)">
            Są wyniki z lab.
          </button>
      </div>

          <!--button [disabled]="isGenerated(order)" *hasRole="'ROLE_UPDATE'" matTooltip="Aktualizacja - kliknięcie przycisku spowoduje wysłanie do klienta najbardziej aktualnej wersji raportu/infografiki, wygenerowanie nowego hasła. Używać z rozwagą." mat-icon-button color="accent" (click)="regenerate(order)">
              <mat-icon>refresh</mat-icon>
          </button>
         <ogen-download  [disabled]="order.reportSendItem && isGenerated(order) && order.reportSendItem" 
                matTooltip="Pacnij jak chce Ci się chce ściągnąć zip z 3 infografikami" 
                mat-icon-button color="accent" 
                title="format_list_numbered"
                color="accent" 
                [isButtonTemplate]="false"
                [url]="'/api/order/infographic/printVersions/' + order.barcode_value">
          
          </ogen-download>
          <button *ngIf="!order.updatable" (click)="enable(order)" matTooltip="Aktywacja aktualizacji raportu" mat-icon-button color="primary"><mat-icon>flash_on</mat-icon></button>
          <button *ngIf="order.updatable" (click)="disable(order)" matTooltip="Dezaktywacja aktualizacji raportu" mat-icon-button color="warn"><mat-icon>flash_off</mat-icon></button>
          <span></span>
        </div>
        <div>
          <button [disabled]="checkReceive(order)" matTooltip="Pacnij jeśli otrzymaliśmy jego ślinę"  mat-icon-button color="primary" (click)="saveReceive(order)">
              <mat-icon>home</mat-icon>
          </button>
          <button [disabled]="checkSend(order)" matTooltip="Pacnij jak wysłaliśmy do labu" fxFlexOffset="20"  mat-icon-button color="primary"  (click)="saveSend(order)">
            <mat-icon>local_shipping</mat-icon>
          </button>
           <button [disabled]="checkResult(order)" matTooltip="Pacnij jak otrzymalismy wynik z laboratorium" fxFlexOffset="20" mat-icon-button color="primary"  (click)="saveResult(order)">
            <mat-icon>receipt</mat-icon>
          </button-->
        </div>
        </section>
      </mat-list-item>
      <mat-divider></mat-divider>
  </div>
</mat-nav-list>
    <order *ngIf="order" [item]="order" (changed)= 'onOrderChange($event)'></order>
