<h2 mat-dialog-title>Wprowadź języki</h2>
<mat-dialog-content>
      <mat-tab-group>
          <mat-tab *ngFor="let lang of langs">
            <ng-template mat-tab-label>
              <mat-icon *ngIf="lang != 'default'" [svgIcon]="lang"></mat-icon>
              <mat-icon *ngIf="lang == 'default'" svgIcon='pl'></mat-icon>
            </ng-template>
            <mat-form-field color="primary" class="lang" >
                <textarea style="width:100%"  matInput [(ngModel)] = "data[lang]" matTextareaAutosize matAutosizeMinRows="1"
                    matAutosizeMaxRows="5"  placeholder="Treść" [readonly] = 'disabled'></textarea>
              </mat-form-field>
          </mat-tab>
       
        </mat-tab-group>


</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button *ngIf="!disabled"(click)=submit()>Wprowadź</button>
  <button mat-button (click)=cancel()>Anuluj</button>
</mat-dialog-actions>

