
<mat-toolbar color="primary">{{order.barcode_value}}</mat-toolbar>

<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10">
<mat-accordion  fxFlex="grow">
  <mat-expansion-panel  
        expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Moduły: 
      </mat-panel-title>
      <mat-panel-description>
        <span *ngFor="let module of order.modules, let first = first"><span *ngIf='!first'>,</span> {{module.name}}</span> 
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-selection-list #modules>
      <span *ngFor="let module of moduleList">
      <mat-list-option  [selected]='isSelected(module)' checkboxPosition="before" [value]="module" >
        {{module.name}}
      </mat-list-option>
    </span>
    </mat-selection-list>
    <button color="primary" mat-button (click)="saveModules()">Zapisz</button>
</mat-expansion-panel>

  
</mat-accordion>


<mat-accordion *ngIf="order.user"  fxFlex="grow">
  <mat-expansion-panel  
  			expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Klient:
      </mat-panel-title>
      <mat-panel-description >
      	{{order.user.email}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div [fxLayout]="order.sampleAddress ? 'column':'row'" fxLayout.sm="column">
     <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Email" [value]="order.user.email"/>

      </mat-form-field>
      <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Imię" [value]="order.user.name"/>
      </mat-form-field>
      <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Nazwisko" [value]="order.user.surname"/>
      </mat-form-field>
<mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Telefon" [value]="order.user.phoneNumber"/>
      </mat-form-field>
</div>
</mat-expansion-panel>


</mat-accordion>

<mat-accordion *ngIf="order.sampleAddress"  fxFlex="grow">
  <mat-expansion-panel  
        expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Adres kuriera:
      </mat-panel-title>
      <mat-panel-description >
        {{order.sampleAddress.city + ' ' + order.sampleAddress.street}}
      </mat-panel-description>
    </mat-expansion-panel-header>

   <div fxLayout="column">
     <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Imię" [value]="order.sampleAddress.name"/>

      </mat-form-field>
      <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Nazwisko" [value]="order.sampleAddress.surname"/>
      </mat-form-field>
      <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Numer telefonu" [value]="order.sampleAddress.phoneNumber"/>
      </mat-form-field>
      <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Adres" [value]="order.sampleAddress.street + ' ' + order.sampleAddress.flatNumber"/>
      </mat-form-field>
      <mat-form-field color="primary" fxFlex>
        <input type="text" matInput readonly="true" placeholder="Adres" [value]="order.sampleAddress.zipCode + ' ' + order.sampleAddress.city"/>
      </mat-form-field>
      <mat-form-field *ngIf="order.sampleAddress.addInfo" color="primary" fxFlex>
        <textarea  matInput matTextareaAutosize matAutosizeMinRows="1"
            matAutosizeMaxRows="5" readonly="true" placeholder="Uwagi" [value]="order.sampleAddress.addInfo"></textarea>
      </mat-form-field>

</div>


</mat-expansion-panel>

</mat-accordion>

</div>
<mat-list>
  <h3 mat-subheader>Zdarzenia</h3>
  <mat-list-item *ngFor="let event of events;">
    <div fxLayout="row" fxLayout.sm="column" fxFlex fxLayoutGap="10" fxLayoutAlign="space-around">
      <mat-form-field color="primary" fxFlex="40%" fxFlex.sm>
        <input type="text" matInput readonly="true" [value]="event.translate()"/>
      </mat-form-field>
      <mat-form-field color="primary" fxFlex="20%" fxFlex.sm>
        <input type="text" matInput readonly="true" placeholder="Kiedy" [value]="event.eventDate"/>
      </mat-form-field>
      <mat-form-field color="primary" fxFlex="30%" fxFlex.sm>
        <input type="text" matInput readonly="true" placeholder="Kto" [value]="event.createdBy"/>
      </mat-form-field>
    </div>
  </mat-list-item>
  <mat-list-item>
    <form #f="ngForm" (ngSubmit)="onSubmit()" fxLayout="row" fxLayout.sm="column" fxFlex fxLayoutGap="10" fxLayoutAlign="space-around">
    
      <mat-form-field color="{{type.errors && type.pristine ? 'warn' : 'primary'}}" fxFlex="40%" fxFlex.sm>
        <mat-select required placeholder="Typ zdarzenia" [(ngModel)]="newEvent.type" #type="ngModel" name="type">
        <mat-option value="EXAMPLE_RECEIVE">Otrzymano próbkę śliny od użytkownika</mat-option>
        <mat-option value="EXAMPLE_SEND_TO_LAB">Wysłano ślinę do laboratorium</mat-option>
        <mat-option value="RESULT_RECEIVE_FROM_LAB">Dostaliśmy plik</mat-option>
      </mat-select>
      <mat-error *ngIf="type.errors">Misiek - coś wybierz</mat-error>
      </mat-form-field>

      <mat-form-field  color="{{eventDate.errors && eventDate.pristine ? 'warn' : 'primary'}}" fxFlex="30%" fxFlex.sm>
    <input matInput required disabled [matDatepicker]="startAtPicker" [(ngModel)]="newEvent.eventDate" placeholder="Data zdarzenia" #eventDate="ngModel"
         name="eventDate">
    <mat-datepicker-toggle matSuffix [for]="startAtPicker"></mat-datepicker-toggle>
    <mat-datepicker #startAtPicker></mat-datepicker>
    <mat-error *ngIf="eventDate.errors">Misiek - coś wybierz</mat-error>
    </mat-form-field>
    <button [disabled]="f.invalid" type="submit" mat-icon-button color="primary">
      <mat-icon>save</mat-icon>
    </button>
    
    </form>
  </mat-list-item>
</mat-list>



