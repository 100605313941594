import { Component, OnInit, Input, OnDestroy, HostBinding, Optional, Self, ViewChild, ElementRef, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { MatDialog, MatFormFieldControl } from '@angular/material';
import { LanguageDialogComponent } from '../language-dialog/language-dialog.component';
import { LangText } from '../../model/section/version';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-language-text',
  templateUrl: './language-text.component.html',
  styleUrls: ['./language-text.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: LanguageTextComponent}],
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
 }
})
export class LanguageTextComponent implements ControlValueAccessor, MatFormFieldControl<LangText>, OnDestroy, OnChanges, DoCheck {


  static nextId = 0;

  constructor(@Optional() @Self() public ngControl: NgControl,public elementRef: ElementRef, public dialog: MatDialog, public fm: FocusMonitor) {

     if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.fm.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  @Input()
  _value: LangText;


  stateChanges = new Subject<void>();
  @HostBinding() id = `app-language-text-${LanguageTextComponent.nextId++}`;

  @Input()
get placeholder() {
  return this._placeholder;
}
set placeholder(plh) {
  this._placeholder = plh;
  this.stateChanges.next();
}
private _placeholder: string;


  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected _disabled = false;

  @Input()
  get required(): boolean { 
    return this._required; 
  }
  set required(value: boolean) {
     this._required = coerceBooleanProperty(value); 
    }
  protected _required = false;

  focused = false;

  errorState = false;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty; 
  }


  controlType = 'language-text';
  autofilled = false;;

  get empty() {
    if (this._value && this._value.default) {
      return false;
    }
    return true;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
     this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if(!this._disabled) {
      this.touched = true;
      this.container.nativeElement.focus();
    }
  }


  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elementRef.nativeElement);
  }

  touched = false;

  onChange = (_: any) => {
    this.stateChanges.next();
  };

  onTouched = () => {this.touched = true;};

  openDialog() {
    const dialogRef = this.dialog.open(LanguageDialogComponent, {width: '60vw',height: '50vh', data: {value : this.value, disabled : this._disabled }});
    if(!this.disabled) {
    this.touched = true;
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      this.value = result;
      }
    });
  }
  }


  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this._setValueInternal();
    this.onChange(this._value);
  }

  private _setValueInternal() {
    if(this.container) {
      if(this.value) {
        this.container.nativeElement.value = this.value.default;
      }
    }
  }

  writeValue(obj: LangText): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._setValueInternal();
  }

  ngDoCheck(): void {
    if(this.ngControl) {
      this.errorState = this.ngControl.invalid && this.touched;
     this.stateChanges.next();
   }
  }

}
