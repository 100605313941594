import { OnInit } from '@angular/core';
import { DownloadService } from '../../services/download.service';
var DownloadButtonComponent = /** @class */ (function () {
    function DownloadButtonComponent(_ds) {
        this._ds = _ds;
        this.color = "primary";
        this.class = "";
        this._disabled = false;
        this.state = 'Clear';
    }
    DownloadButtonComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(DownloadButtonComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (disabled) {
            this._disabled = disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DownloadButtonComponent.prototype, "isDownloading", {
        get: function () {
            return this.state == 'Downloading';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DownloadButtonComponent.prototype, "label", {
        get: function () {
            if (this.isButtonTemplate) {
                if (this.state == 'Clear')
                    return this.title;
                else if (this.state == 'Downloading')
                    return "Trwa pobieranie";
                else
                    return "Pobierz";
            }
            else {
                if (this.state == 'Clear')
                    return this.title ? this.title : "cloud_download";
                else if (this.state == 'Downloading')
                    return "cloud_done";
                else
                    return "cloud_done";
            }
        },
        enumerable: true,
        configurable: true
    });
    DownloadButtonComponent.prototype.click = function () {
        var _this = this;
        if (this._disabled || this.state == 'Downloading') {
            return;
        }
        else if (this.state == 'Clear') {
            this.state = 'Downloading';
            this._ds.downloadUrl(this.createUrl()).subscribe(function (data) {
                console.log(data);
                _this.downloadContent = data;
                _this.state = 'Downloaded';
            }, function (error) { return console.log("Error downloading file!"); });
        }
        else {
            this.state = "Clear";
            var url = window.URL.createObjectURL(this.downloadContent);
            window.open(url);
            this.downloadContent = null;
        }
    };
    DownloadButtonComponent.prototype.createUrl = function () {
        if (this.section) {
            return this.url + '/section/' + this.section;
        }
        if (this.version) {
            return this.url + '/version/' + this.version;
        }
        return this.url;
    };
    return DownloadButtonComponent;
}());
export { DownloadButtonComponent };
