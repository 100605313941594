<mat-toolbar color = "primary" class="fixed-top">

  <button *ngIf="isMenuBtnVisible" (click)=menu() mat-icon-button ><!--fxHide.gt-md-->
    <mat-icon aria-label="menu">menu</mat-icon>
  </button>

    


    <span style="flex: 1 1 auto;text-align: center;">{{ label }}</span>

  <button (click)=logout() mat-icon-button>
    <mat-icon aria-label="Logout">exit_to_app</mat-icon>
  </button>
</mat-toolbar>


