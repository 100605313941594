<div *ngIf="!isLoaded" fxLayout="column" fxLayoutAlign="center center">
	<h2>{{loadingmsg}}</h2>
	 <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        diameter="100"
        >
    </mat-progress-spinner>
</div>
<mat-card *ngIf="isLoaded" class="card">
     <mat-card-title-group>
        <mat-card-title >Aktualizacja zamówienia {{orderCode}}</mat-card-title>
    </mat-card-title-group>


<mat-card-content gdAreas="header header | report1 report2 | info1 info2 | raw1 raw2" gdGap="1em" gdAlignColumns="center" gdColumns="200px auto">
    <span gdArea="header" class="header">
        <div><mat-icon>star</mat-icon> => aktualna wersja</div>
        <div><mat-icon>done</mat-icon> => wersja do której będzie aktualizacja</div>
    </span>
	<span gdArea="report1">
		<mat-slide-toggle color="primary" [(ngModel)]="includeReport">Raport</mat-slide-toggle>
    </span>
    <span gdArea="report2">
  		<mat-chip-list multiple="false" [disabled]="!includeReport" >
        	<mat-chip *ngFor="let version of oiv.availableVersions;index as i" selectable="true" color="primary" [disabled]="version.compareTo(oiv.report) < 0" [selected]="version.compareTo(oiv.report) >= 0  && selectedVersion['report'].equal(version)" (click)="versionSelectChange(version,'report')" >
 			<mat-icon *ngIf="selectedVersion['report'].equal(version)"  matChipAvatar>done</mat-icon>
 			{{version.value()}}
 			<mat-icon *ngIf="version.equal(oiv.report)" matChipTrailingIcon>star</mat-icon>
 			</mat-chip>
        </mat-chip-list>
	</span>
	<span gdArea="info1" [fxHide]="disableInfographic">
		<mat-slide-toggle color="primary" [(ngModel)]="includeInfographic">Infografika</mat-slide-toggle>
    </span>
    <span gdArea="info2" [fxHide]="disableInfographic">
  		<mat-chip-list multiple="false" [disabled]="!includeInfographic" >
        	<mat-chip *ngFor="let version of oiv.availableVersions;index as i" selectable="true" color="primary" [disabled]="version.compareTo(oiv.infographic) < 0" [selected]="version.compareTo(oiv.infographic) >= 0  && selectedVersion['infographic'].equal(version)" (click)="versionSelectChange(version,'infographic')" >
 			<mat-icon *ngIf="selectedVersion['infographic'].equal(version)"  matChipAvatar>done</mat-icon>
 			{{version.value()}}
 			<mat-icon *ngIf="version.equal(oiv.infographic)" matChipTrailingIcon>star</mat-icon>
 			</mat-chip>
        </mat-chip-list>		
	</span>
	<span gdArea="raw1">
		<mat-slide-toggle color="primary" [(ngModel)]="includeRawData">Surowe Dane</mat-slide-toggle>
    </span>
    <span gdArea="raw2">
		  		<mat-chip-list multiple="false" [disabled]="!includeRawData" >
        	<mat-chip *ngFor="let version of oiv.availableVersions;index as i" selectable="true" color="primary" [disabled]="version.compareTo(oiv.rawData) < 0" [selected]="version.compareTo(oiv.rawData) >= 0  && selectedVersion['rawData'].equal(version)" (click)="versionSelectChange(version,'rawData')" >
 			<mat-icon *ngIf="selectedVersion['rawData'].equal(version)"  matChipAvatar>done</mat-icon>
 			{{version.value()}}
 			<mat-icon *ngIf="version.equal(oiv.rawData)" matChipTrailingIcon>star</mat-icon>
 			</mat-chip>
        </mat-chip-list>
	</span>
</mat-card-content>
<mat-card-footer class="footer" fxLayout="column" fxLayoutGap="20px">

    <span *ngIf="!doNothing()">
        <div *ngIf="isSafeOperation()" class='info'>Aktualizujesz wszystkie 3 rzeczy do tej samej {{ selectedVersion.infographic.value() }} wersji. Klient dostanie maila z aktualizowanym 1 hasłem do wszystkiego.</div>
        <div *ngIf="!isSafeOperation()" class='warn'>Wersje trzech rzeczy (raport, surowe i infografika)<span *ngIf="!all()"> i hasła</span> rozjadą się.<span *ngIf="all()" class='info'>Klient dostanie maila z aktualizowanym 1 hasłem do wszystkiego.</span>
            <br>Raport -><span [ngClass]="reportTouched() ? 'info' : 'warn'"> {{this.reportTouched() ? "Zaktualizuje się do wersji " + this.selectedVersion.report.value() + "." : "Zostanie w wersji "+ this.oiv.report.value() + "."}}</span> <span [ngClass]="includeReport ? 'info' : 'warn'"> {{this.includeReport ? "Hasło zostanie zmienione na nowe" : "Aktualne hasło będzie nadal aktywne"}}</span>
             <br>Infografika -><span [ngClass]="infographicTouched() ? 'info' : 'warn'"  [fxHide]="disableInfographic"> {{this.infographicTouched() ? "Zaktualizuje się do wersji " + this.selectedVersion.infographic.value() + "." : "Zostanie w wersji "+ this.oiv.infographic.value() + "."}}</span> <span [ngClass]="includeInfographic ? 'info' : 'warn'"  [fxHide]="disableInfographic"> {{this.includeInfographic ? "Hasło zostanie zmienione na nowe" : "Aktualne hasło będzie nadal aktywne"}}</span>
              <br>Surowe dane -><span [ngClass]="rawDataTouched() ? 'info' : 'warn'"> {{this.rawDataTouched() ? "Zaktualizuje się do wersji " + this.selectedVersion.rawData.value() + "." : "Zostanie w wersji "+ this.oiv.rawData.value() + "."}}</span> <span [ngClass]="includeRawData ? 'info' : 'warn'"> {{this.includeRawData ? "Hasło zostanie zmienione na nowe" : "Aktualne hasło będzie nadal aktywne"}}</span>

        </div>
    </span>
    <div fxLayout="row" fxLayoutGap="20px">
        <button mat-flat-button [disabled]="doNothing()" (click)="save()" color="primary">Zapisz</button>
        <button mat-flat-button color="primary" (click)="close()">Anuluj</button>
    </div>
</mat-card-footer>


</mat-card>
