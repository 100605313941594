import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LangText } from '../../model/section/version';

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styles: ['lang { margin-top: 10px; width: 200px }','mat-form-field {width: 100%;}']
})
export class LanguageDialogComponent {

  public langs:  string[];
  public data: LangText;
  public disabled: boolean;


  constructor( public dialogRef: MatDialogRef<LanguageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private entry: any) {
      this.disabled = entry.disabled;
      this.data =  new LangText(entry.value);
      this.langs = Object.keys(this.data);
     }

    cancel() {
      this.dialogRef.close();
    }

    submit() {
      this.dialogRef.close(this.data);
    }
}
