import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import {Order,OrderEvent} from '../../model/order';
import {OrderService} from '../../services/order.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ModuleService } from '../../services/module-service';
import { Module } from '../../model/module';
import { MatSelectionList } from '@angular/material';
import { SnackBarHelper } from '../../components/helpers/snack-bar-helper';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

	private _order: Order;
  events: OrderEvent[];
  newEvent: OrderEvent;
  moduleList: Module[];
  @ViewChild('modules') modules: MatSelectionList;
  @Output() changed = new EventEmitter<Order>();


  constructor(private orderService:OrderService,private moduleService:ModuleService,private snackBar: SnackBarHelper) {
      this.events = new Array<OrderEvent>() 
      this.newEvent = new OrderEvent();
      this.newEvent.eventDate = new Date();
      this.moduleList = moduleService.getModules();      
    }

  ngOnInit() {
  }

  isSelected(module:Module) {
    return this._order.modules.some(t => t.name === module.name);
  }

  saveModules() {
    this.orderService.saveOrderModules(this._order.barcode_value, this.modules.selectedOptions.selected.map(t => t.value)).subscribe(t =>
      {
          this.snackBar.openSnackBar("Zapisano moduły dla klienta");
          //this.order = t.result;
          this.changed.emit(t.result);
      });
    
  }

  @Input('item')
  set order(order:Order) {
    if(this._order) {
      if(this._order.barcode_value == order.barcode_value) {
        this._order = order;
        return;
      }
    }

  	this._order = order;
    this.onOrderChange();
  }

  get order():Order {
  	return this._order;
  }

  onOrderChange() {
    
    this.orderService.getOrderEvents(this._order.barcode_value).subscribe(data => {
      if(data.success) {
        this.events = (data.result as OrderEvent[]).map(v => {let order = new OrderEvent()
         order.createdBy = v.createdBy;
         order.eventDate = v.eventDate;
         order.type = v.type;
         order.eventData = v.eventData;
         return order;
        });
      } else {
        console.log(this.events);
      }
    });
  }

  onSubmit() {
    this.orderService.saveEvent(this._order.barcode_value,this.newEvent).subscribe(data => {
      if(data.success) {
          this.onOrderChange();
          this.newEvent = new OrderEvent();
          this.newEvent.eventDate = new Date();
      } else {
        console.log(data);
      }
    })
  }
}
