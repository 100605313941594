import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment';

@Injectable()
export class DownloadService {

  constructor(private http:HttpClient) { }




  public downloadUrl(url: string) {
     return this.http.get(environment.apiUrl + url,{responseType: "blob"});  
  }

  public downloadReport(version:number,section:number) {
    if(section) {
      return this.http.get(environment.apiUrl + '/api/report/dummy/section/' + section,{responseType: "blob"});  
    }

    if(version) {
      return this.http.get(environment.apiUrl + '/api/report/dummy/version/' + version,{responseType: "blob"});  
    }

    return this.http.get(environment.apiUrl + '/api/report/dummy',{responseType: "blob"});  
  }


  public downloadInfographic() {
     return this.http.get(environment.apiUrl + '/api/infographic/dummy',{responseType: "blob"});  

  }

}
