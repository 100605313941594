import { OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
var NavComponent = /** @class */ (function () {
    function NavComponent(router, auth, location) {
        this.router = router;
        this.auth = auth;
        this.location = location;
        this.openMenu = new EventEmitter();
    }
    NavComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(NavComponent.prototype, "label", {
        get: function () {
            return environment.envName;
        },
        enumerable: true,
        configurable: true
    });
    NavComponent.prototype.logout = function () {
        this.auth.logout();
        this.router.navigate(['login']);
    };
    NavComponent.prototype.menu = function () {
        this.openMenu.emit(true);
    };
    Object.defineProperty(NavComponent.prototype, "isMenuBtnVisible", {
        get: function () {
            return this._isMenuBtnVisible;
        },
        set: function (isMenuBtnVisible) {
            this._isMenuBtnVisible = isMenuBtnVisible;
        },
        enumerable: true,
        configurable: true
    });
    return NavComponent;
}());
export { NavComponent };
