import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionService } from '../../services/section.service';
import { MatSnackBar } from '@angular/material';
import { ModuleService } from '../../services/module-service';
var TextSectionComponent = /** @class */ (function () {
    function TextSectionComponent(route, snackBar, sectionService, router, moduleService) {
        var _this = this;
        this.route = route;
        this.snackBar = snackBar;
        this.sectionService = sectionService;
        this.router = router;
        this.moduleService = moduleService;
        this.sm = false;
        this.moduleService.getModulesAsObservable().subscribe(function (t) { return _this.moduleList = t; });
        this.section = {
            id: 0,
            type: 'Text',
            content: { 'default': '' },
            title: { 'default': '' },
            name: '',
            priority: 0,
            parentId: 0,
            versionId: 0,
            backgroundImage: '',
            color: '',
            childrenId: [],
            modules: []
        };
    }
    TextSectionComponent.prototype.moduleCompare = function (o1, o2) {
        return o1.id == o2.id;
    };
    TextSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actionType = this.route.snapshot.data['actionType'];
        this.parentType = this.route.snapshot.data['parentType'];
        if (this.actionType != 'new') {
            this.sub = this.route.params.subscribe(function (params) {
                _this.id = +params['id'];
                _this.sectionService.getSection(_this.id).subscribe(function (data) {
                    _this.section = data.result;
                    console.log(_this.section);
                    if (_this.section.id != _this.id) {
                        throw "Wynik z servera nie zgadza się z danymi";
                    }
                });
            });
        }
        else {
            this.sub = this.route.params.subscribe(function (params) {
                _this.parentId = +params['parentId'];
            });
        }
    };
    TextSectionComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    Object.defineProperty(TextSectionComponent.prototype, "actionlabel", {
        get: function () {
            if (this.actionType == 'new')
                return 'Nowy rozdział';
            if (this.actionType == 'edit')
                return 'Edycja rozdziału';
            if (this.actionType == 'view')
                return 'Podgląd rozdziału';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextSectionComponent.prototype, "readonly", {
        get: function () {
            return this.actionType == 'view';
        },
        enumerable: true,
        configurable: true
    });
    TextSectionComponent.prototype.back = function () {
        var url;
        if (this.actionType == 'new') {
            if (this.parentType == 'section') {
                url = '/app/paragraph/' + this.parentId + '/view';
            }
            else {
                url = '/app/version/' + this.parentId + '/view';
            }
        }
        else {
            if (this.section.parentId) {
                url = '/app/paragraph/' + this.section.parentId + '/view';
            }
            else {
                url = '/app/version/' + this.section.versionId;
            }
        }
        this.router.navigate([url]);
    };
    TextSectionComponent.prototype.onTitleChange = function (data) {
        if (this.actionType == 'new') {
            if (data) {
                this.section.name = data.default;
            }
        }
    };
    TextSectionComponent.prototype.onReturn = function (data) {
        console.log(data);
        if (data.success == true) {
            this.snackBar.open('OK', '', { duration: 3000 });
            this.router.navigate(['/app/paragraph/' + data.result.id + '/view']);
        }
        else {
            throw data.errors;
        }
    };
    TextSectionComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.actionType == 'new') {
            this.sectionService.addTextSection(this.section, this.parentId, this.parentType).subscribe(function (data) { return _this.onReturn(data); }, function (error) { return console.log(error); });
        }
        else if (this.actionType == 'edit') {
            this.sectionService.updateTextSection(this.section).subscribe(function (data) { return _this.onReturn(data); }, function (error) { return console.log(error); });
        }
    };
    TextSectionComponent.prototype.calculateIcon = function (section) {
        if (section.type == 'Gene') {
            return 'share';
        }
        else if (section.childs && section.childs > 9) {
            return 'filter_9_plus';
        }
        else if (section.childs && section.childs > 0 && section.childs <= 9) {
            return 'filter_' + section.childs;
        }
        else {
            return 'filter';
        }
    };
    return TextSectionComponent;
}());
export { TextSectionComponent };
