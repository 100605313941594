import { Directive } from "@angular/core";

import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, FormControl, ValidatorFn } from '@angular/forms';

export function requiredLangValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;
    if ( value && value.default ) {
      return null;
    }
    return {'required' : true};
  };
}


@Directive({
// tslint:disable-next-line: directive-selector
    selector: '[requiredLang] [ngModel]',
    providers: [{provide: NG_VALIDATORS, useExisting: RequiredLangDirective, multi: true}]
  })
export class RequiredLangDirective implements Validator {


    validate(control: FormControl): ValidationErrors {
      return requiredLangValidator()(control);
    }


    registerOnValidatorChange?(fn: () => void): void {

    }

}
