import { Component } from '@angular/core';
import { MatDialog } from '@angular/material'
import { SNPValueProbability } from '../../model/response'

@Component({
  selector: 'app-check-dialog',
  templateUrl: './check-dialog.component.html',
  styleUrls: ['./check-dialog.component.scss']
})
export class CheckDialogComponent {
  
  public items:SNPValueProbability[];
 
  constructor(public dialog: MatDialog) { }



}
