
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './controllers/login/login.component';
import { HomeComponent } from './controllers/home/home.component';
import { NavComponent } from './controllers/nav/nav.component';
import { VersionsComponent } from './controllers/versions/versions.component';
import { OrdersComponent } from './controllers/orders/orders.component';

import { VersionComponent } from './controllers/version/version.component';
import { TextSectionComponent } from './controllers/text-section/text-section.component';
import { GeneSectionFormComponent } from './controllers/gene-section-form/gene-section-form.component';
import { StatisticsComponent } from './controllers/statistics/statistics.component';
import { UpdateComponent } from './controllers/update/update.component';
import { NoAccessComponent} from './controllers/no-access/no-access.component';
import { PageNoFoundComponent } from './controllers/page-no-found/page-no-found.component';
//import { OrderUpdateComponent } from './controllers/order-update/order-update.component';

import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';
import { LanguageTextComponent } from './components/language-text/language-text.component';
import { ModuleComponent } from './controllers/module/module.component';


const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
 

  { path: 'app', component: HomeComponent, canActivate: [AuthGuard],data: {exceptedRole:"*"}, children: [
   { path: '', redirectTo: 'orders', pathMatch: 'full' },
  { path: 'noaccess', component: NoAccessComponent, canActivate: [AuthGuard],data: {exceptedRole:"*"}},
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard],data: {exceptedRole:"ROLE_ORDER"}},
  { path: 'versions', component: VersionsComponent, canActivate: [AuthGuard],data: {exceptedRole:"ROLE_REPORT"}},
  { path: 'analitics', component: StatisticsComponent, canActivate: [AuthGuard],data: {exceptedRole:"ROLE_ANAL"}},
  { path: 'update', component: UpdateComponent, canActivate: [AuthGuard],data: {exceptedRole:"ROLE_UPDATE"}},
  { path: 'module', component: ModuleComponent, canActivate: [AuthGuard],data: {exceptedRole:"ROLE_MODULE"}},
  { path: 'version/:versionId', component: VersionComponent, canActivate: [AuthGuard],data: {exceptedRole:"ROLE_REPORT"} },


  { path: 'paragraph/:id/edit', component: TextSectionComponent, canActivate: [AuthGuard],data: {actionType:"edit",exceptedRole:"ROLE_REPORT"}},
  { path: 'paragraph/:id/view', component: TextSectionComponent, canActivate: [AuthGuard],data: {actionType:"view",exceptedRole:"ROLE_REPORT"}},
  { path: 'section/:id/edit', component: GeneSectionFormComponent, canActivate: [AuthGuard],data: {actionType:"edit",exceptedRole:"ROLE_REPORT"}},
  { path: 'section/:id/view', component: GeneSectionFormComponent, canActivate: [AuthGuard],data: {actionType:"view",exceptedRole:"ROLE_REPORT"}},
  { path: 'version/:parentId/paragraph', component: TextSectionComponent, canActivate: [AuthGuard],data: {actionType:"new",parentType:"version",exceptedRole:"ROLE_REPORT"}},
  { path: 'version/:parentId/section', component: GeneSectionFormComponent, canActivate: [AuthGuard],data: {actionType:"new",parentType:"version",exceptedRole:"ROLE_REPORT"}},
  { path: 'section/:parentId/paragraph', component: TextSectionComponent, canActivate: [AuthGuard],data: {actionType:"new",parentType:"section",exceptedRole:"ROLE_REPORT"}},
  { path: 'section/:parentId/section', component: GeneSectionFormComponent, canActivate: [AuthGuard],data: {actionType:"new",parentType:"section",exceptedRole:"ROLE_REPORT"}},
  ]},
  //{ path: 'test', component: OrderUpdateComponent,canActivate: [LoginGuard]},
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'test', component: LanguageTextComponent, canActivate: [LoginGuard] },

  { path: '**', component: PageNoFoundComponent, canActivate: [LoginGuard] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
