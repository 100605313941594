
<form role="form" (submit)="onLogin()">
 <mat-card>
   <mat-card-header>
      <img mat-card-image src="/assets/images/logo.svg" alt="Ogen"/>
    </mat-card-header>
  <mat-card-content>
      <mat-form-field>
        <input matInput type="text" required [(ngModel)]="user.email" id="username" name="username" placeholder="Login użytkowinika" > 
        <mat-icon matSuffix>face</mat-icon>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password" required [(ngModel)]="user.password" id="password" name="password" placeholder="Hasło użytkownika">
          <mat-icon matSuffix>lock_open</mat-icon>
      </mat-form-field>
      <mat-error *ngIf="badCredential">Błędne dane misiaczki</mat-error>
  </mat-card-content>
 <mat-card-actions>
  <button mat-raised-button color="primary" type="submit" name="action">LOGIN</button>
</mat-card-actions>
</mat-card>
</form >