import { Observable } from 'rxjs';
import { ModuleService } from './../../services/module-service';
import { Component, OnInit } from '@angular/core';
import { Module } from '../../model/module';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit {
  public modules: Observable<Module[]>;
  public moduleFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ]);
  constructor(private moduleService: ModuleService) {
    this.modules = this.moduleService.getModulesAsObservable();
   }

  ngOnInit() {
  }


  public submit() {
    if(this.moduleFormControl.valid) {
        this.moduleService.addModules(this.moduleFormControl.value).subscribe(
          t => console.log(t),
          err => console.log(err)
        )
    }
  }

}
