import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatSnackBar } from '@angular/material';
import { FormGroup, FormArray } from '@angular/forms';
import { SectionService } from '../../services/section.service';
import { UIService } from '../../services/ui.service';
import { GeneSectionFormBuilderService } from '../../services/gene-section-form-builder.service';
import { GeneSection } from '../../model/section/version';
import { environment } from '../../../environments/environment';
import { ModuleService } from '../../services/module-service';
var GeneSectionFormComponent = /** @class */ (function () {
    // tslint:disable-next-line: max-line-length
    function GeneSectionFormComponent(route, moduleService, snackBar, uiService, sectionService, router, fb, iconRegistry, sanitizer) {
        var _this = this;
        this.route = route;
        this.moduleService = moduleService;
        this.snackBar = snackBar;
        this.uiService = uiService;
        this.sectionService = sectionService;
        this.router = router;
        this.fb = fb;
        this.moduleService.getModulesAsObservable().subscribe(function (t) { return _this.moduleList = t; });
        this.progress = true;
        this.sectionForm = this.fb.createForm();
        this.section = new GeneSection();
        this.uiService.getAllBground().subscribe(function (data) {
            if (data.success) {
                _this.backgrounds = data.result;
                _this.backgrounds.forEach(function (value) {
                    iconRegistry
                        .addSvgIcon(value, sanitizer.bypassSecurityTrustResourceUrl(uiService.createBgroundIconLink(value)));
                });
            }
        });
    }
    GeneSectionFormComponent.prototype.moduleCompare = function (o1, o2) {
        return o1.id == o2.id;
    };
    GeneSectionFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actionType = this.route.snapshot.data['actionType'];
        this.parentType = this.route.snapshot.data['parentType'];
        this.uiService.getAllSliders().subscribe(function (data) {
            if (data.success) {
                _this.sliders = data.result;
            }
        });
        if (this.actionType != 'new') {
            this.sub = this.route.params.subscribe(function (params) {
                _this.id = +params['id'];
                _this.sectionService.getSection(_this.id).subscribe(function (data) {
                    if (data.result.id != _this.id) {
                        throw "Wynik z servera nie zgadza się z danymi";
                    }
                    _this.section = data.result;
                    _this.fb.populateForm(_this.section, _this.sectionForm);
                    _this.progress = false;
                });
            });
        }
        else {
            this.sub = this.route.params.subscribe(function (params) {
                _this.parentId = +params['parentId'];
            });
        }
        this.onTitleChange();
    };
    GeneSectionFormComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    GeneSectionFormComponent.prototype.loadBackground = function (value) {
        if (value.length > 4) {
        }
    };
    GeneSectionFormComponent.prototype.onTitleChange = function () {
        var _this = this;
        this.sectionForm.get('title').valueChanges.subscribe(function (val) {
            if (_this.actionType == 'new') {
                if (val) {
                    _this.section.name = val.default;
                }
            }
        });
    };
    GeneSectionFormComponent.prototype.createLink2 = function (value) {
        var bgroundUrl = environment.apiUrl + '/public/ui/bgrounds/' + value;
        return bgroundUrl;
    };
    GeneSectionFormComponent.prototype.createLink = function (value) {
        if (this.sliders) {
            for (var _i = 0, _a = this.sliders; _i < _a.length; _i++) {
                var data = _a[_i];
                if (data.first == value) {
                    return data.second;
                }
            }
        }
        return null;
    };
    GeneSectionFormComponent.prototype.createLabelForSnpValue = function (subsection) {
        var label = "Podaj wartość (";
        subsection.controls['snps'].controls.forEach(function (c, index, array) {
            label = label + c.controls['snpValue'].value;
            if (index != array.length - 1) {
                label = label + '+';
            }
        });
        label = label + ")";
        return label;
    };
    GeneSectionFormComponent.prototype.addSubSectionTab = function () {
        this.fb.addSubSection(this.sectionForm);
    };
    GeneSectionFormComponent.prototype.closeSubSectionTab = function (index) {
        this.fb.removeSubSection(this.sectionForm, index);
    };
    GeneSectionFormComponent.prototype.addVariantTab = function (subsection) {
        this.fb.addVariantTab(subsection);
    };
    GeneSectionFormComponent.prototype.closeVariantTab = function (index, subsection) {
        this.fb.removeVariantTab(subsection, index);
    };
    GeneSectionFormComponent.prototype.addSnpsTab = function (subsection) {
        this.fb.addSnp(subsection);
    };
    GeneSectionFormComponent.prototype.closeSnpsValueTab = function (index, variant) {
        this.fb.removeSnpValue(variant, index);
    };
    GeneSectionFormComponent.prototype.addSnpsValueTab = function (variant) {
        this.fb.addSnpValue(variant);
    };
    GeneSectionFormComponent.prototype.closeSnpsTab = function (index, subsection) {
        this.fb.removeSnp(subsection, index);
    };
    Object.defineProperty(GeneSectionFormComponent.prototype, "subsectionForms", {
        get: function () {
            return this.sectionForm.controls['subsections'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeneSectionFormComponent.prototype, "actionlabel", {
        get: function () {
            if (this.actionType == 'new')
                return 'Nowa sekcja z genami';
            if (this.actionType == 'edit')
                return 'Edycja sekcji z genami';
            if (this.actionType == 'view')
                return 'Podgląd sekcji z genami';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeneSectionFormComponent.prototype, "readonly", {
        get: function () {
            return this.actionType == 'view';
        },
        enumerable: true,
        configurable: true
    });
    GeneSectionFormComponent.prototype.back = function () {
        var url;
        if (this.actionType == 'new') {
            if (this.parentType == 'section') {
                url = '/app/paragraph/' + this.parentId + '/view';
            }
            else {
                url = '/app/version/' + this.parentId;
            }
        }
        else {
            if (this.section.parentId) {
                url = '/app/paragraph/' + this.section.parentId + '/view';
            }
            else {
                url = '/app/version/' + this.section.versionId;
            }
        }
        this.router.navigate([url]);
    };
    GeneSectionFormComponent.prototype.onReturn = function (data) {
        this.progress = false;
        if (data.success == true) {
            this.snackBar.open('OK', '', { duration: 3000 });
            this.router.navigate(['/app/section/' + data.result.id + '/view']);
        }
        else if (data.success == false && data.errors) {
            this.errors = data.errors;
        }
        else {
            throw data.errors;
        }
    };
    GeneSectionFormComponent.prototype.onSubmit = function () {
        var _this = this;
        this.progress = true;
        if (this.actionType == 'new') {
            this.sectionService.addGeneSection(this.fb.getGeneSection(this.sectionForm, this.section), this.parentId, this.parentType).subscribe(function (data) { return _this.onReturn(data); });
        }
        else if (this.actionType == 'edit') {
            this.sectionService.updateGeneSection(this.fb.getGeneSection(this.sectionForm, this.section)).subscribe(function (data) { return _this.onReturn(data); });
        }
    };
    return GeneSectionFormComponent;
}());
export { GeneSectionFormComponent };
