import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'
import { SectionService } from '../../services/section.service';
import { TextSection } from '../../model/section/version';
import { ResponseEntity} from '../../model/response';
import { NgForm } from '@angular/forms';
import { MatSnackBar} from '@angular/material';
import { ModuleService } from '../../services/module-service';
import { Module } from '../../model/module';


@Component({
  selector: 'app-text-section',
  templateUrl: './text-section.component.html',
  styleUrls: ['./text-section.component.scss']
})
export class TextSectionComponent implements OnInit {
	sm:boolean = false;
	id: number;
	actionType:string;
	parentType:string;
  parentId:number;
  private sub: any;
  public moduleList: Module[];


  	section:TextSection;


 	constructor(private route: ActivatedRoute,public snackBar: MatSnackBar,private sectionService:SectionService,private router:Router,private moduleService:ModuleService,) {
     this.moduleService.getModulesAsObservable().subscribe(t => this.moduleList = t);
 		this.section = {
       id:0,
       type:'Text',
       content: {'default':''},
       title: {'default':''},
       name:'',
       priority:0,
       parentId:0,
       versionId:0,
       backgroundImage:'',
       color:'',
       childrenId:[],
       modules: []
     } as TextSection;
   }
   
   moduleCompare(o1: any, o2: any) {
       return o1.id == o2.id
   }

  	ngOnInit() {

    this.actionType = this.route.snapshot.data['actionType'];
    this.parentType = this.route.snapshot.data['parentType'];
    
    if(this.actionType != 'new') {  
    this.sub = this.route.params.subscribe(params => {
       this.id = +params['id'];
       		this.sectionService.getSection(this.id).subscribe(data => {
                   this.section = data.result;
                   console.log(this.section);
       						if(this.section.id != this.id) {
       							throw "Wynik z servera nie zgadza się z danymi";
		       			
       						}
       					});
   		
    })
  } else {
    this.sub = this.route.params.subscribe(params => {
      this.parentId = +params['parentId'];
    });
  }
    
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get actionlabel() {
  	if(this.actionType == 'new') return 'Nowy rozdział';
	if(this.actionType == 'edit') return 'Edycja rozdziału';
  	if(this.actionType == 'view') return 'Podgląd rozdziału';
  }

  get readonly() {
  	return this.actionType =='view';
  }

   back() {
    let url:string;
    if(this.actionType == 'new') {
      if(this.parentType == 'section') {
        
        url = '/app/paragraph/' + this.parentId + '/view';
      } else {
        
        url =  '/app/version/' + this.parentId + '/view';
      }

    } else {
      if(this.section.parentId) {
        
        url = '/app/paragraph/'+this.section.parentId + '/view';
      } else {
        url = '/app/version/'+this.section.versionId;
      }
    }
    this.router.navigate([url]);
    
  }

  onTitleChange(data:any) {
   if(this.actionType == 'new') {
     if(data) {
      this.section.name = data.default;
     }
      }

  }

  onReturn(data: ResponseEntity) {
    console.log(data);
		if(data.success == true) {
       this.snackBar.open('OK','',{duration: 3000});
       this.router.navigate(['/app/paragraph/'+data.result.id + '/view']);
  		} else {
        
  			throw data.errors;
  		}

  }

  onSubmit() {
  	if(this.actionType == 'new') {
  		this.sectionService.addTextSection(this.section,this.parentId,this.parentType).subscribe(data => this.onReturn(data), error => console.log(error));
  	} else if(this.actionType =='edit') {
    		this.sectionService.updateTextSection(this.section).subscribe(data => this.onReturn(data), error => console.log(error));
  	}
  }
 
  calculateIcon(section:any) {
    if(section.type == 'Gene') {
      return 'share';
    } else if (section.childs && section.childs > 9){
      return 'filter_9_plus';
    } else if (section.childs && section.childs > 0 && section.childs <= 9){
      return 'filter_' + section.childs;
    } else {
      return 'filter';
    }
  }

}
