
    <mat-nav-list>
    <h3 mat-subheader>Wersje</h3>
      <a mat-list-item *ngFor="let version of versions; last as last" [routerLink]="['/app/version', version.id]">
        <mat-icon mat-list-icon>android</mat-icon>
        <h4 mat-line>Wersja: {{version.major}}.{{version.minor}} stan: {{version.state}}</h4>
        <p mat-line>
          <span>Zmodyfikowana ostatnio: {{version.modified}} przez {{version.modifiedBy}}</span>
        </p>
        <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
      </a>
    </mat-nav-list>

