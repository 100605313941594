import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { JwtHelperService } from '../services/jwt-helper.service';


@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private router:Router, private auth:AuthService, private jwt:JwtHelperService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    let returnV = false;
    let isLogged = this.auth.isLogged();
    const role = next.data.exceptedRole;
  	if(!isLogged) {
  		this.router.navigate(['login']);
  	} else {
       if(role == "*") {
         returnV= true
       } else {

     		returnV = this.hasRole(role);
        if(!returnV) {this.router.navigate([this.findRedirect()]);}
        
        }
	  }
    return returnV;
  }

  hasRole(role:string):boolean {
     let user = this.auth.getUser();
     if(user) {
       if(user.roles.includes('ROLE_ADMIN')) {
         return true;
       }
       const dupsko = user.roles.includes(role);
        return dupsko;
     }
     return false;
  }

  findRedirect() {
    if(this.hasRole("ROLE_ORDER")) {
      return '/app/orders'
    }
    if(this.hasRole("ROLE_REPORT")) {
      return '/app/versions'
    }
    if(this.hasRole("ROLE_ANAL")) {
      return '/app/analitics'
    }
    if(this.hasRole("ROLE_UPDATE")) {
      return '/app/update'
    }
    return '/app/noaccess'
    }  

}
