  <button fxFlex="grow" *ngIf="isButtonTemplate"  [disabled] = "disabled" [ngClass] = "class" [color] = "color" mat-flat-button (click)="click()">
  		<span>{{label}}</span>
  		<mat-spinner *ngIf="isDownloading" class="noblock" color="accent" strokeWidth="3" diameter="18"> </mat-spinner>
  </button>	
  


  <button *ngIf="!isButtonTemplate" [disabled] = "disabled" mat-icon-button class="white-color" (click)="click()">
  		<mat-icon  *ngIf="!isDownloading">{{label}}</mat-icon>
  		<mat-spinner *ngIf="isDownloading" class="noblock" color="accent" strokeWidth="3" diameter="18"> </mat-spinner>
  </button>	
  