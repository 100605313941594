import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { User } from '../model/user';
import { ResponseEntity } from '../model/response';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '../services/jwt-helper.service';

@Injectable()
export class AuthService {
	private authUrl:string;

  constructor(private _http:HttpClient,private jwt:JwtHelperService) {
  		this.authUrl = environment.apiUrl;
   }


  login(user:User) {
  		return this._http.post<User>(
  			this.authUrl + '/auth/login',
  			{"login" : user.email,"password": user.password},{ observe: 'response' }
  			);
  }

  saveUser(user:User,token:string) {
  	localStorage.setItem('user',JSON.stringify(user));
  	localStorage.setItem('token',token);
  }

  logout() {
  	localStorage.removeItem('user');
  	localStorage.removeItem('token');	
  }

  isLogged(){
 	 if(!localStorage.getItem('user')) {
 	 	return false;
 	 }  else {
    let token = localStorage.getItem('token');
    if(this.jwt.isTokenExpired(token)) {
      console.log('token is expired');
      localStorage.removeItem('user');
      return false
    }
    return true;
  }
}
  getUser() {
    let stringUser = localStorage.getItem('user');
    return JSON.parse(stringUser) as User;
  }
 	 
  
 


}
