
<div fxLayoutAlign="space-between">
  <button mat-button matSuffix mat-icon-button aria-label="Back"  (click)="back()">
       <mat-icon>undo</mat-icon>
  </button>
  <h3>{{actionlabel}}</h3>
   <button matSuffix mat-icon-button aria-label="Edit" [disabled]="!readonly" [routerLink]="['/app/paragraph',section?.id,'edit']">
        <mat-icon>edit</mat-icon>
    </button>

</div>

<form #f="ngForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-slide-toggle
          color="accent"
          [checked]="section?.raportEnabled"
          [disabled]="readonly" name="raportEnabled" id="raportEnabled" [(ngModel)]="section.raportEnabled" #raportEnabled="ngModel">
          {{raportEnabled.value ? 'Sekcja widoczna   ' : 'Sekcja niewidoczna'}}
      </mat-slide-toggle>
      <span>{{section?.name || 'Nowa sekcja'}}</span>
      <ogen-download [isButtonTemplate]="false" [disabled] = "!readonly" matTooltip="Zostanie wygenerowany raport zawierający tylko jeden rozdział (zawierający tą sekcję)" [section]="section?.id" url="/api/report/dummy"></ogen-download>
      </mat-toolbar>
      <mat-card-content>

      <div fxLayout="row" fxLayoutAlign="space-around end"  fxLayoutGap="20px" fxLayout.sm="column">
        <mat-form-field fxFlex="30" fxFlex.sm="100" color="{{title.errors && !title.pristine ? 'warn' : 'primary'}}">
            <app-language-text requiredLang [(ngModel)] = "section.title" (ngModelChange)="onTitleChange($event)" name="title" id="title" #title="ngModel" [disabled] = "readonly" placeholder="Tytuł"></app-language-text>
          <mat-error *ngIf="title.invalid">Zla wartosc</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20" fxFlex.sm="100" color="{{modules.errors && !modules.pristine ? 'warn' : 'primary'}}">
          <mat-label>Moduły</mat-label>
          <mat-select [(ngModel)] = "section.modules"  #modules="ngModel" name="modules" id="modules" [disabled] = "readonly" placeholder="Moduły" [compareWith]="moduleCompare" multiple>
              <mat-option *ngFor="let obj of moduleList" [value]="obj">{{obj.name}}</mat-option>
          </mat-select>
      </mat-form-field>
        <mat-form-field fxFlex="20" fxFlex.sm="100" color="{{priority.errors && !priority.pristine ? 'warn' : 'primary'}}">
          <input matInput required pattern="[1-9]\d*" placeholder="Priorytet" [(ngModel)]="section.priority" #priority="ngModel" name="priority" type="number" [readonly] = "readonly" id="priority"/>
          <mat-error *ngIf="priority.invalid">Zla wartosc</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="30" fxFlex.sm="100" color="{{color.errors && !color.pristine ? 'warn' : 'primary'}}">
          <input matInput required  placeholder="Kolor paska" [(ngModel)]="section.color" #color="ngModel" name="color" type="color" [readonly] = "readonly" id="color"/>
          <mat-error *ngIf="color.invalid">Zla wartosc {{section.color}}</mat-error>
        </mat-form-field> 
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
       <mat-form-field fxFlex="100" color="{{c.errors && !c.pristine ? 'warn' : 'primary'}}">
          <app-language-text requiredLang [(ngModel)] = "section.content" name="c" #c="ngModel" [disabled]="readonly" placeholder="Tresc"></app-language-text>
          
        <!--textarea required matInput [readonly]="readonly" [(ngModel)] = "section.content.default" #c="ngModel" id="c" matTextareaAutosize matAutosizeMinRows="1"
            matAutosizeMaxRows="5"  name="c" placeholder="Treść"></textarea>
        -->
         <mat-error *ngIf="c.invalid">Zła wartosc</mat-error>
      </mat-form-field>
      </div>
        
      </mat-card-content>
       <mat-card-actions>
      <button *ngIf="readonly" style="margin-right: 36px;margin-bottom: 16px;" mat-raised-button color="primary" [routerLink]="['/app/section',section?.id,'section']">Dodaj sekcje z genami</button>
      <button *ngIf="readonly" style="margin-right: 36px;margin-bottom: 16px;" mat-raised-button color="primary"  [routerLink]="['/app/section',section?.id,'paragraph']">Dodaj rozdział</button>
      <button *ngIf="!readonly" mat-raised-button style="margin-right: 36px;margin-bottom: 16px;"  [disabled]="f.invalid">Zapisz</button>

      </mat-card-actions>

    </mat-card>
</form>

<mat-nav-list *ngIf="actionType == 'view' && section?.childrenId">
  <h3  mat-subheader>Sekcje</h3>
    <div *ngFor="let section of section?.childrenId">
      <mat-list-item class="list-item">
        <a [routerLink]="section?.type == 'Text' ? ['/app/paragraph',section.id,'view'] : ['/app/section',section.id,'edit']" >
             <mat-icon [color]="section.type == 'Text' ? 'primary' : 'warn'">{{calculateIcon(section)}}</mat-icon></a>
             <a  fxFlexOffset="1" [class.strike-through] = "!section.enabled" [routerLink]="section?.type == 'Text' ? ['/app/paragraph',section.id,'view'] : ['/app/section',section.id,'edit']" >
              <span>{{section.name}}</span> <span style="color: darkgreen;">Typ: {{section.type}} </span> <span style="color: fuchsia;font-style: italic;" *ngIf="section.modules">  Moduły: {{section.modules}}</span>
            
            </a>
        
        <span style="flex: 1 1 auto;"></span>
        <section class="margin-right" >
          <button [routerLink]="[section.type == 'Text' ? '/app/paragraph': '/app/section',section.id,'view']" mat-mini-fab color="primary" >
              <mat-icon>visibility</mat-icon>
          </button>
          <button  [routerLink]="[section.type == 'Text' ? '/app/paragraph': '/app/section',section.id,'edit']" fxFlexOffset="20" mat-mini-fab color="primary"  >
            <mat-icon>edit</mat-icon>
          </button>
        </section>
      </mat-list-item>
      <mat-divider></mat-divider>
  </div>
</mat-nav-list>
