import { OnInit, EventEmitter } from '@angular/core';
import { OrderEvent } from '../../model/order';
import { OrderService } from '../../services/order.service';
import { ModuleService } from '../../services/module-service';
import { MatSelectionList } from '@angular/material';
import { SnackBarHelper } from '../../components/helpers/snack-bar-helper';
var OrderComponent = /** @class */ (function () {
    function OrderComponent(orderService, moduleService, snackBar) {
        this.orderService = orderService;
        this.moduleService = moduleService;
        this.snackBar = snackBar;
        this.changed = new EventEmitter();
        this.events = new Array();
        this.newEvent = new OrderEvent();
        this.newEvent.eventDate = new Date();
        this.moduleList = moduleService.getModules();
    }
    OrderComponent.prototype.ngOnInit = function () {
    };
    OrderComponent.prototype.isSelected = function (module) {
        return this._order.modules.some(function (t) { return t.name === module.name; });
    };
    OrderComponent.prototype.saveModules = function () {
        var _this = this;
        this.orderService.saveOrderModules(this._order.barcode_value, this.modules.selectedOptions.selected.map(function (t) { return t.value; })).subscribe(function (t) {
            _this.snackBar.openSnackBar("Zapisano moduły dla klienta");
            //this.order = t.result;
            _this.changed.emit(t.result);
        });
    };
    Object.defineProperty(OrderComponent.prototype, "order", {
        get: function () {
            return this._order;
        },
        set: function (order) {
            if (this._order) {
                if (this._order.barcode_value == order.barcode_value) {
                    this._order = order;
                    return;
                }
            }
            this._order = order;
            this.onOrderChange();
        },
        enumerable: true,
        configurable: true
    });
    OrderComponent.prototype.onOrderChange = function () {
        var _this = this;
        this.orderService.getOrderEvents(this._order.barcode_value).subscribe(function (data) {
            if (data.success) {
                _this.events = data.result.map(function (v) {
                    var order = new OrderEvent();
                    order.createdBy = v.createdBy;
                    order.eventDate = v.eventDate;
                    order.type = v.type;
                    order.eventData = v.eventData;
                    return order;
                });
            }
            else {
                console.log(_this.events);
            }
        });
    };
    OrderComponent.prototype.onSubmit = function () {
        var _this = this;
        this.orderService.saveEvent(this._order.barcode_value, this.newEvent).subscribe(function (data) {
            if (data.success) {
                _this.onOrderChange();
                _this.newEvent = new OrderEvent();
                _this.newEvent.eventDate = new Date();
            }
            else {
                console.log(data);
            }
        });
    };
    return OrderComponent;
}());
export { OrderComponent };
