import { Component, OnInit, OnDestroy } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ActivatedRoute } from '@angular/router'
import { SectionService } from '../../services/section.service';
import { Version } from '../../model/section/version';
import { ResponseEntity } from '../../model/response';
import { SnackBarHelper } from '../../components/helpers/snack-bar-helper';
import { MatDialog,MatDialogRef,MatDialogConfig } from '@angular/material'
import { CheckDialogComponent } from '../check-dialog/check-dialog.component'


@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit,OnDestroy {

  id: number;
  private sub: any;
  version:Version;
  private dialogConfig:MatDialogConfig;



constructor(private route: ActivatedRoute,private sectionService:SectionService,private snack:SnackBarHelper,private dialog:MatDialog,private overlay:Overlay) {
  this.dialogConfig = new MatDialogConfig;
  this.dialogConfig.scrollStrategy = overlay.scrollStrategies.block();
  this.dialogConfig.minWidth="40vw";
  this.dialogConfig.maxWidth="80vw";
}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
       this.id = +params['versionId']; // (+) converts string 'id' to a number
       this.sectionService.getVersion(this.id).subscribe(data => {
         if(data.success) {

           this.version = data.result;
           console.log(this.version);
          
         } else {
            this.snack.openSnackBarEntity(data);
         }
       });
    });

  }


  checkVersion() {
    this.sectionService.checkVersion(this.id).subscribe(data=> {
      if(data.result.error) {
         

         let dialogRef = this.dialog.open(CheckDialogComponent,this.dialogConfig);
         dialogRef.componentInstance.items = data.result.scores;
      } else {
        this.snack.openSnackBar("ok");
      }
  }, err => this.snack.openSnackBarError(err));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  publish() {
    this.sectionService.publishVersion(this.id).subscribe(data => this.handleResponse(data), err => this.snack.openSnackBarError(err));
  }

  public refresh(data:ResponseEntity) {
    console.log(data);
    this.handleResponse(data);
  }

  private handleResponse(data:ResponseEntity) {
    if(data.success) {
      this.version = data.result 
    } 
    this.snack.openSnackBarEntity(data);
  }

  createVersion(isMajor:boolean) {
    this.sectionService.createVersion(isMajor,this.version).subscribe(data => this.handleResponse(data), err => this.snack.openSnackBarError(err));
  }

  calculateIcon(section:any) {
    if(section.type == 'Gene') {
      return 'share';
    } else if (section.childs && section.childs > 9){
      return 'filter_9_plus';
    } else if (section.childs && section.childs > 0 && section.childs <= 9){
      return 'filter_' + section.childs;
    } else {
      return 'filter';
    }
  }
}
