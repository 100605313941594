 <h2 mat-dialog-title>WYniki sprawdzenia raportu - zdublowane RS`y</h2>

    <mat-dialog-content>
    	<div fxLayout="row" fxLayoutGap="3">
    		<span fxFlex="15">RS</span>
    		<span fxFlex="10">Wartość</span>
    		<span fxFlex="10">%</span>
    		<span fxFlexOffset="30" fxFlex="66">Sekcja</span>
    	</div>
    	<span *ngFor="let item of items; let i=index;let odd=odd;let even=even"> 
    	<div fxLayout="row"  fxLayoutGap="3" [class.odd] ="odd" [class.even] ="even">
    		<span fxFlex="15">{{item.snp}}</span>
    		<span fxFlex="10">{{item.value}}</span>
    		<span fxFlex="10">{{item.probability}}%</span>
    		<span fxFlexOffset="30" fxFlex="66">{{item.sectionName}}</span>
		</div>
		<br *ngIf="items[i+1] && items[i+1].snp != item.snp"/> 
	</span>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        mat-dialog-close>Close</button>
    </mat-dialog-actions>