import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseEntity } from '../model/response';



@Injectable()
export class UIService {

	_url:string;

   constructor(private _http:HttpClient) {
  	this._url = environment.apiUrl; 
	}

	getAllSliders() {
		return this._http.get<ResponseEntity>(this._url+'/public/ui/sliders');
	}
	getAllBground() {
		return this._http.get<ResponseEntity>(this._url+'/public/ui/bgrounds');
	}
	
	createBgroundIconLink(value:string) {
		return this._url+ '/public/ui/bgrounds/' + value;
	}	

}
