import { Component, OnInit } from '@angular/core';
import { StatsService} from '../../services/stats.service';
import { OrderSummary } from '../../model/order';
import { SnackBarHelper } from '../../components/helpers/snack-bar-helper';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

	public statsLines:any;

  constructor(private service:StatsService,private snackBar:SnackBarHelper) { }

  ngOnInit() {
  	this.service.getStats().subscribe(data => {
  		if(data.success) {this.statsLines = data.result.summary;}
  		else {console.log(data)};
  	});
  }

  translate(i:any):string {
  	switch(i.type) {
			case 'RESERVED_TO_USER': {return "Przypisany użyszkodnik :";}
			case 'EXAMPLE_RECEIVE': {return "Otrzymano próbkę śliny od użytkownika :";}
			case 'EXAMPLE_SEND_TO_LAB': {return "Wysłano ślinę do laboratorium :";}
			case 'RESULT_RECEIVE_FROM_LAB': {return "Otrzymano plik z laboratorium :";}
      case 'ARTIFACTS_GENERATED': {return "Wygenerowano: ";}
      case 'MODULE_ADDED': {return "Dodano moduły dla zamówienia: ";}
			default : return '<' + i.type+ '>';

		}
  }


  orderReport() {
    this.service.orderReport().subscribe(data => {
      if(data.success) {this.snackBar.openSnackBar("Jak sie wygeneruje to dojdzie: " + data.result)}
        else {console.log(data)}
    });
  }


}
