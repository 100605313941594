import { OnInit, EventEmitter } from '@angular/core';
import { SectionService } from '../../services/section.service';
import { SnackBarHelper } from '../helpers/snack-bar-helper';
var MailTemplateComponent = /** @class */ (function () {
    function MailTemplateComponent(_service, snack) {
        this._service = _service;
        this.snack = snack;
        this.onSaved = new EventEmitter();
        this._readonly = true;
    }
    MailTemplateComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(MailTemplateComponent.prototype, "version", {
        get: function () {
            return this._version;
        },
        set: function (version) {
            this._version = version;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MailTemplateComponent.prototype, "readonly", {
        get: function () {
            return this._readonly;
        },
        set: function (readonly) {
            this._readonly = readonly;
            this.mailMsg = this._msg;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MailTemplateComponent.prototype, "msg", {
        get: function () {
            return this._msg;
        },
        set: function (msg) {
            this._readonly = true;
            this._msg = msg;
            this.mailMsg = msg;
        },
        enumerable: true,
        configurable: true
    });
    MailTemplateComponent.prototype.saveVersion = function () {
        var _this = this;
        this._service.addMsg(this.mailMsg, this._version)
            .subscribe(function (data) {
            _this.onSaved.emit(data);
        }, function (err) {
            _this.snack.openSnackBarError(err);
        });
    };
    MailTemplateComponent.prototype.sendMail = function () {
        var _this = this;
        this._service.sendMail(this.mailMsg).subscribe(function (data) { _this.snack.openSnackBarEntity(data); }, function (err) { _this.snack.openSnackBarError(err); });
    };
    return MailTemplateComponent;
}());
export { MailTemplateComponent };
