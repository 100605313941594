import { Component, OnInit,Output,Input, EventEmitter } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SectionService } from '../../services/section.service';
import { Location } from '@angular/common';
import { DownloadService } from '../../services/download.service'; 
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
	

  private _isMenuBtnVisible:boolean;
  @Output() public openMenu = new EventEmitter<boolean>();

  constructor(private router:Router, private auth:AuthService,private location:Location) { }

  ngOnInit() { 
       

  }

 

  get label() {
    return environment.envName;
  }

  logout() {
  	this.auth.logout();
  	this.router.navigate(['login']);
  }

  menu() {
     this.openMenu.emit(true);
  }

  @Input() 
  set isMenuBtnVisible(isMenuBtnVisible:boolean) {
    this._isMenuBtnVisible = isMenuBtnVisible;
  }

  get isMenuBtnVisible() {
    return this._isMenuBtnVisible;
  }

}
