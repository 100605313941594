import { OnDestroy, ElementRef, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { MatDialog, MatFormFieldControl } from '@angular/material';
import { LanguageDialogComponent } from '../language-dialog/language-dialog.component';
import { LangText } from '../../model/section/version';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
var LanguageTextComponent = /** @class */ (function () {
    function LanguageTextComponent(ngControl, elementRef, dialog, fm) {
        var _this = this;
        this.ngControl = ngControl;
        this.elementRef = elementRef;
        this.dialog = dialog;
        this.fm = fm;
        this.stateChanges = new Subject();
        this.id = "app-language-text-" + LanguageTextComponent.nextId++;
        this._disabled = false;
        this._required = false;
        this.focused = false;
        this.errorState = false;
        this.controlType = 'language-text';
        this.autofilled = false;
        this.describedBy = '';
        this.touched = false;
        this.onChange = function (_) {
            _this.stateChanges.next();
        };
        this.onTouched = function () { _this.touched = true; };
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        this.fm.monitor(elementRef.nativeElement, true).subscribe(function (origin) {
            _this.focused = !!origin;
            _this.stateChanges.next();
        });
    }
    Object.defineProperty(LanguageTextComponent.prototype, "placeholder", {
        get: function () {
            return this._placeholder;
        },
        set: function (plh) {
            this._placeholder = plh;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LanguageTextComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = coerceBooleanProperty(value);
            if (this.focused) {
                this.focused = false;
                this.stateChanges.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LanguageTextComponent.prototype, "required", {
        get: function () {
            return this._required;
        },
        set: function (value) {
            this._required = coerceBooleanProperty(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LanguageTextComponent.prototype, "shouldLabelFloat", {
        get: function () {
            return this.focused || !this.empty;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(LanguageTextComponent.prototype, "empty", {
        get: function () {
            if (this._value && this._value.default) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    LanguageTextComponent.prototype.setDescribedByIds = function (ids) {
        this.describedBy = ids.join(' ');
    };
    LanguageTextComponent.prototype.onContainerClick = function (event) {
        if (!this._disabled) {
            this.touched = true;
            this.container.nativeElement.focus();
        }
    };
    LanguageTextComponent.prototype.ngOnDestroy = function () {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elementRef.nativeElement);
    };
    LanguageTextComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(LanguageDialogComponent, { width: '60vw', height: '50vh', data: { value: this.value, disabled: this._disabled } });
        if (!this.disabled) {
            this.touched = true;
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.value = result;
                }
            });
        }
    };
    Object.defineProperty(LanguageTextComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this._value = val;
            this._setValueInternal();
            this.onChange(this._value);
        },
        enumerable: true,
        configurable: true
    });
    LanguageTextComponent.prototype._setValueInternal = function () {
        if (this.container) {
            if (this.value) {
                this.container.nativeElement.value = this.value.default;
            }
        }
    };
    LanguageTextComponent.prototype.writeValue = function (obj) {
        if (obj !== undefined) {
            this.value = obj;
        }
    };
    LanguageTextComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    LanguageTextComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    LanguageTextComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    LanguageTextComponent.prototype.ngOnChanges = function (changes) {
        this._setValueInternal();
    };
    LanguageTextComponent.prototype.ngDoCheck = function () {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.touched;
            this.stateChanges.next();
        }
    };
    LanguageTextComponent.nextId = 0;
    return LanguageTextComponent;
}());
export { LanguageTextComponent };
