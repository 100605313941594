import { Component, OnInit,OnDestroy } from '@angular/core';
import { AuthService } from './services/auth.service';
import { MatSnackBar, MatSnackBarConfig, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ModuleService} from './services/module-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private moduleService: ModuleService,
    private authService: AuthService
  ) {
    this.matIconRegistry.addSvgIcon(
      "pl",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/flags/pl.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "en",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/flags/en.svg")
    );
    if(this.authService.isLogged()) {
      this.moduleService.initModules();
    }
    
}
}