export class User {

	name:string;
	surname:string;
	roles:string[];

	constructor(public email?:string, public password?:string){
		this.name='';
		this.surname='';
		this.roles=[];

	};



}
