import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from "@angular/flex-layout";


//routing module
import { AppRoutingModule } from './app-routing.module';
//AngularMaterial wrapper module
import { NgMaterialModule} from './app.ngmaterial';



import { AppComponent } from './app.component';
import { TokenInterceptor } from './interceptors/token.interceptor';


import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';
import { AuthService } from './services/auth.service'
import { JwtHelperService } from './services/jwt-helper.service';
import { OrderService } from './services/order.service';
import { DownloadService} from './services/download.service';
import { SectionService} from './services/section.service';
import { UIService } from './services/ui.service';
import { StatsService } from './services/stats.service';
import { ModuleService} from './services/module-service';
import { SnackBarHelper } from './components/helpers/snack-bar-helper';

import { GeneSectionFormBuilderService } from './services/gene-section-form-builder.service';

import { LoginComponent } from './controllers/login/login.component';
import { HomeComponent } from './controllers/home/home.component';
import { NavComponent } from './controllers/nav/nav.component';
import { VersionsComponent } from './controllers/versions/versions.component';
import { VersionComponent } from './controllers/version/version.component';
import { TextSectionComponent } from './controllers/text-section/text-section.component';
import { GeneSectionFormComponent } from './controllers/gene-section-form/gene-section-form.component';
import { OrderComponent } from './controllers/order/order.component';
import { DownloadButtonComponent } from './components/download-button/download-button.component';
import { StatisticsComponent } from './controllers/statistics/statistics.component';
import { MailTemplateComponent } from './components/mail-template/mail-template.component';
import { CheckDialogComponent } from './controllers/check-dialog/check-dialog.component';
import { UpdateComponent } from './controllers/update/update.component';
import { UserCanAccessDirective } from './directive/user-can-access.directive';
import { NoAccessComponent } from './controllers/no-access/no-access.component';
import { OrdersComponent } from './controllers/orders/orders.component';
import { PageNoFoundComponent } from './controllers/page-no-found/page-no-found.component';
import { DropListControllerComponent } from './controllers/drop-list-controller/drop-list-controller.component';
import { DropControllerComponent } from './controllers/drop-controller/drop-controller.component';
import { OrderUpdateComponent } from './controllers/order-update/order-update.component';
import { LanguageTextComponent } from './components/language-text/language-text.component';
import { LanguageDialogComponent } from './components/language-dialog/language-dialog.component';
import { RequiredLangDirective } from './directive/required-lang-directive';
import { ModuleComponent } from './controllers/module/module.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavComponent,
    VersionsComponent,
    VersionComponent,
    TextSectionComponent,
    GeneSectionFormComponent,
    OrderComponent,
    DownloadButtonComponent,
    StatisticsComponent,
    MailTemplateComponent,
    CheckDialogComponent,
    UpdateComponent,
    UserCanAccessDirective,
    NoAccessComponent,
    OrdersComponent,
    PageNoFoundComponent,
    DropListControllerComponent,
    DropControllerComponent,
    OrderUpdateComponent,
    LanguageTextComponent,
    LanguageDialogComponent,
    RequiredLangDirective,
    ModuleComponent,
  ],
  entryComponents: [CheckDialogComponent,OrderUpdateComponent,LanguageDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    AppRoutingModule,
    NgMaterialModule
  ],
  providers: [
  { 
    provide: HTTP_INTERCEPTORS, 
    useClass: TokenInterceptor, 
    multi: true 
  },


  AuthService,
  JwtHelperService,
  OrderService,
  DownloadService,
  SectionService,
  StatsService,
  ModuleService,
  
  GeneSectionFormBuilderService,
  UIService,
  SnackBarHelper,

  AuthGuard,
  LoginGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
