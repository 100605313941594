import { Component, OnInit, QueryList } from '@angular/core';
import {MatListOption} from '@angular/material'
import { OrderService} from '../../services/order.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  public loading:boolean=true;
  public orders:any;


  constructor(private _orderService:OrderService) { }

  ngOnInit() {
  	this.refresh();
  }

  update(items:QueryList<MatListOption>) {
  	console.log(items);
  	let excludeCodes = items.filter(item => !item.selected).map(item => item.value) as string[];
  	this.loading = true;
  	this._orderService.regenerateBatch(excludeCodes).subscribe(data=> this.refresh(),error=> {console.log(error);this.loading=false; });
  }

  refresh() {
	this.loading=true;
  	this._orderService.getOrdersForUpdate().subscribe(
  		data=> {
  			this.orders=data.result;
  			this.loading=false;
  		}
  		);
  }


}
