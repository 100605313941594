import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SectionService } from '../../services/section.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import {MediaChange, MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent  {
 title:string;
  public _opened:boolean = false;
 
 // private watcher: any;
  public _version:string;
  
  

  constructor(private media: MediaObserver,private section:SectionService,public snackBar: MatSnackBar,private auth:AuthService,private router:Router ) {
    this.title='Ogen internal';
    if(environment.snackMsg) {
      let config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.horizontalPosition = 'end';
      config.duration = 0;
      config.panelClass = undefined;
      this.snackBar.open(environment.snackMsg, 'Kapuje', config);
    }
    if(!this.auth.isLogged()) {
          this.router.navigate(['login']);
    }
      this.section.getMaxVersion().subscribe(data => this._version = data.result);
  }

  get version() {
   return this._version ? this._version : "";
 }

  set opened(opened:boolean) {
    this._opened = opened;
  }

  get opened() {
    if(!this.auth.isLogged()) {
      return false;
    }
    if(this.media.isActive("gt-md")) {
      return true;
    }
    return this._opened;
  }

  get hasBackdrop() {
    return !this.media.isActive("gt-md");
  }

  get mode() {
    return this.media.isActive("gt-md") ? "side":"over";
  }

/*  ngOnDestroy() {
    this.watcher.unsubscribe();
  }**/


  get isVisible() {
    return this.auth.isLogged();
  }

  
  get isMenuBtnVisible() {
    return !this.opened;
  }


onDeactivate(e) {
   document.body.scrollTop = 0;
}

}
