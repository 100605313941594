<span fxLayout="column" fxLayoutAlign="start stretch">
	
	<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center" fxlayoutGap="20">
		<span class="mat-body">Zmiany (do maila):</span>
		<span fxFlex></span>
		<span *ngIf="readonly"><button (click)="readonly=!readonly" mat-icon-button><mat-icon>edit</mat-icon></button></span>
		<span *ngIf="!readonly"><button (click)="readonly=!readonly" mat-icon-button><mat-icon>visibility</mat-icon></button></span>
		<span><button (click)="sendMail()" mat-icon-button><mat-icon>mail</mat-icon></button></span>
	</mat-toolbar>

 <mat-form-field [color] ="readonly?'primary':'accent'">
 	<textarea  [readonly]="readonly" matTextareaAutosize matAutosizeMinRows="1"
        matAutosizeMaxRows="5" matInput [(ngModel)]="mailMsg"></textarea>
        <mat-hint *ngIf="!readonly" align="end">
        	<button class="textarea-icon" color="primary" style="color:green" mat-icon-button (click) = "saveVersion()">
        		<mat-icon>check</mat-icon>
        	</button>
        	<button style="color:red" (click)="readonly=!readonly" class="textarea-icon" color="primary" mat-icon-button>
        		<mat-icon>close</mat-icon>
        	</button>
        </mat-hint>
 </mat-form-field>

    

</span>

