<p>
  statistics works!
</p>
<span *ngIf="statsLines">
	<div *ngFor="let line of statsLines"> {{translate(line)}} {{line.count}}</div>
</span>
<div>
	<button mat-raised-button color="primary" (click)="orderReport()">Dawaj raport</button>
</div>

