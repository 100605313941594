/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/common";
import * as i7 from "@angular/material/form-field";
import * as i8 from "./language-text.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/cdk/a11y";
var styles_LanguageTextComponent = [i0.styles];
var RenderType_LanguageTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageTextComponent, data: {} });
export { RenderType_LanguageTextComponent as RenderType_LanguageTextComponent };
function View_LanguageTextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "middle grey_bgr mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["more_horiz"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LanguageTextComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "max_width"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["container", 1]], null, 1, "input", [["class", "no_border"], ["fxFlex", ""], ["readonly", ""], ["type", "text"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i4.DefaultFlexDirective, [i1.ElementRef, i5.StyleUtils, i5.LAYOUT_CONFIG, i4.FlexStyleBuilder, i5.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageTextComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.disabled; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LanguageTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-language-text", [], [[8, "id", 0], [1, "aria-describedby", 0], [2, "floating", null]], null, null, View_LanguageTextComponent_0, RenderType_LanguageTextComponent)), i1.ɵprd(6144, null, i7.MatFormFieldControl, null, [i8.LanguageTextComponent]), i1.ɵdid(2, 966656, null, 0, i8.LanguageTextComponent, [[8, null], i1.ElementRef, i9.MatDialog, i10.FocusMonitor], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = i1.ɵnov(_v, 2).describedBy; var currVal_2 = i1.ɵnov(_v, 2).shouldLabelFloat; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var LanguageTextComponentNgFactory = i1.ɵccf("app-language-text", i8.LanguageTextComponent, View_LanguageTextComponent_Host_0, { _value: "_value", placeholder: "placeholder", disabled: "disabled", required: "required" }, {}, []);
export { LanguageTextComponentNgFactory as LanguageTextComponentNgFactory };
