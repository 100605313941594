/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-access.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-access.component";
var styles_NoAccessComponent = [i0.styles];
var RenderType_NoAccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoAccessComponent, data: {} });
export { RenderType_NoAccessComponent as RenderType_NoAccessComponent };
export function View_NoAccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wyglada na to ze nie masz dostepu jak uwazasz \u017Ce mie\u0107 go powinne\u015B mie\u0107 - to zglaszaj do Karola."])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PS: strona startowa (tam gdzie sa zamowienia tez ma pewne uprawnienia)."])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Podsumowuj\u0105c - przestan sie mazac i klikaj w menu. Nie masz w menu to co chcia\u0142e\u015B - pisz do Karola"]))], null, null); }
export function View_NoAccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-access", [], null, null, null, View_NoAccessComponent_0, RenderType_NoAccessComponent)), i1.ɵdid(1, 114688, null, 0, i2.NoAccessComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoAccessComponentNgFactory = i1.ɵccf("app-no-access", i2.NoAccessComponent, View_NoAccessComponent_Host_0, {}, {}, []);
export { NoAccessComponentNgFactory as NoAccessComponentNgFactory };
