
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center">
  <mat-progress-spinner fxFlexOffset="2" mode="indeterminate" color="accent" [strokeWidth]="10" [diameter]="128"></mat-progress-spinner>
  <h2>Trwa ładowanie wynikow aktualizacji</h2>
</div>

<div *ngIf="!loading">
	<h2 fxFlexAlign="center">Aktualizacja do wersji {{orders.major}}.{{orders.minor}}</h2>
  <h2 fxFlexAlign="center">Aktualizacja obejmuje również infografikę i surowe dane. Jeśli chcesz oddzielnie skorzystaj z innej funckjonalności.</h2>
	<h4 fxFlexAlign="center">Uwaga: po kliknięciu aktualizuj nalezy dac serwerowi czas aby przetworzyl te raporty... </h4>  
</div>
<div *ngIf="!loading" fxLayout="row" fxLayoutAlign="center start">
	<div fxFlex>

			<mat-selection-list #updateList>
      		<h3 mat-subheader>Lista zamówień do aktualizacji</h3>
   		 <div fxLayout="row" fxLayoutAlign="space-evenly center">
      		<button mat-stroked-button (click)="updateList.selectAll()">Zaznacz wszystkie</button>
      		<button mat-stroked-button (click)="updateList.deselectAll()">Odznacz wszystkie</button>
      		<button mat-raised-button color="primary" (click)="update(updateList.options)">Aktualizuj zaznaczone</button>
    	</div>
      		<mat-list-option *ngFor="let order of orders.forUpdates" selected  [value]="order.orderCode">
      			<h3 mat-line>{{order.orderCode}} ver: {{order.oldVersion}} {{order.user}}</h3>
      		</mat-list-option>
   		 </mat-selection-list>

	</div>
	<div fxFlex>
		<mat-selection-list disabled>
      		<h3 mat-subheader>Lista probek które się nie załapały</h3>
      		<mat-list-option *ngFor="let order of orders.notForUpdates"  value="order.orderCode">
      			<h3 mat-line><b>{{order.orderCode}}</b> ver: {{order.oldVersion}}</h3>
		        <p mat-line >{{order.user}}</p>
		         <p mat-line >{{order.reason}}</p>
      		</mat-list-option>
      		
   		 </mat-selection-list>
   </div>
</div>

 

