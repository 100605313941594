import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SectionService } from '../../services/section.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { MediaObserver } from '@angular/flex-layout';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(media, section, snackBar, auth, router) {
        var _this = this;
        this.media = media;
        this.section = section;
        this.snackBar = snackBar;
        this.auth = auth;
        this.router = router;
        this._opened = false;
        this.title = 'Ogen internal';
        if (environment.snackMsg) {
            var config = new MatSnackBarConfig();
            config.verticalPosition = 'top';
            config.horizontalPosition = 'end';
            config.duration = 0;
            config.panelClass = undefined;
            this.snackBar.open(environment.snackMsg, 'Kapuje', config);
        }
        if (!this.auth.isLogged()) {
            this.router.navigate(['login']);
        }
        this.section.getMaxVersion().subscribe(function (data) { return _this._version = data.result; });
    }
    Object.defineProperty(HomeComponent.prototype, "version", {
        get: function () {
            return this._version ? this._version : "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "opened", {
        get: function () {
            if (!this.auth.isLogged()) {
                return false;
            }
            if (this.media.isActive("gt-md")) {
                return true;
            }
            return this._opened;
        },
        set: function (opened) {
            this._opened = opened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "hasBackdrop", {
        get: function () {
            return !this.media.isActive("gt-md");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "mode", {
        get: function () {
            return this.media.isActive("gt-md") ? "side" : "over";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "isVisible", {
        /*  ngOnDestroy() {
            this.watcher.unsubscribe();
          }**/
        get: function () {
            return this.auth.isLogged();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "isMenuBtnVisible", {
        get: function () {
            return !this.opened;
        },
        enumerable: true,
        configurable: true
    });
    HomeComponent.prototype.onDeactivate = function (e) {
        document.body.scrollTop = 0;
    };
    return HomeComponent;
}());
export { HomeComponent };
