import { SnackBarHelper } from './../components/helpers/snack-bar-helper';
import { BehaviorSubject, throwError } from "rxjs";
import { Module } from "../model/module";
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ResponseEntity } from "../model/response";
import { tap, catchError } from "rxjs/operators";


@Injectable()
export class ModuleService implements OnDestroy {

    ngOnDestroy(): void {
        this._modulesSubject.complete();
    }

    private _modulesSubject = new BehaviorSubject<Module[]>([]);
    private moduleUrl: string;


    constructor(private _http: HttpClient, private helper: SnackBarHelper ) {
        this.moduleUrl = environment.apiUrl + '/api/modules'; 
      }

    public initModules() : void {
        this._http.get<ResponseEntity>(this.moduleUrl).subscribe(t => {
            if (t.success) {
            let results = t.result as any[];
            let newModules = [];
            results.forEach(t => {
                newModules.push(new Module(t.id, t.name));
            });
            console.log(newModules);
              this._modulesSubject.next(newModules);
            } else {
                console.log(t.errors);
            }
        }, err => console.log(err));
    }

    public getModules() {
        return this._modulesSubject.value;
    }

    public getModulesAsObservable() {
        return this._modulesSubject.asObservable();
    }

    public addModules(module: string) {
      return this._http.post<ResponseEntity>(this.moduleUrl, {'name' : module}).pipe(
          tap(t => {
            const oldModules = this._modulesSubject.value;
            oldModules.push(t.result);
            this._modulesSubject.next(oldModules);
            }),
            catchError(err => {console.log(err); this.helper.openSnackBar(err); return throwError(err) })
            );
    }

}
