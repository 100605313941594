import { Module } from "./module";


export interface Order {
	barcode_value:string;
	sampleAddress:SimpleAddress;
	reportSendItem:SimpleAddress;
	user:Client;
	updatable:boolean;
	modules:Module[];
	events:OrderEvent[];
	
}

export interface SimpleAddress {
	name:string;
	surname:string;
	phoneNumber:string;
	street:string;
	city:string;
	flatNumber:string;
	zipCode:string;
	addInfo:string;
}

export interface Client {
	name:string;
	surname:string;
	email:string;
}

export class OrderEvent{
	createdBy:string;
	type:string;
	eventDate:Date;
	eventData:any;


	
	translate():string {
		
		switch(this.type) {
			case 'CREATED': {return "Utworzono zamówienie";}
			case 'RESERVED_TO_USER': {return "Przypisany użyszkodnik";}
			case 'EXAMPLE_RECEIVE': {return "Otrzymano próbkę śliny od użytkownika";}
			case 'EXAMPLE_SEND_TO_LAB': {return "Wysłano ślinę do laboratorium";}
			case 'RESULT_RECEIVE_FROM_LAB': {return "Otrzymano plik z laboratorium";}
			case 'RAW_DATA_GENERATED': {return "Stworzono paczkę z surowymi danymi (" + this.eventData.version + ")";}
			case 'REPORT_GENERATED': {
				if(this.eventData) {
					return "Wygenerowano raport w wersji: " + this.eventData.version  + ' ' + this.eventData.moduleList;;
				} else {
					return "Wygenerowano raport";
				}
			}
			case 'INFO_GENERATED': {
				if(this.eventData) {
					return "Wygenerowano infografike w wersji: " + this.eventData.version  + ' ' + this.eventData.moduleList;;
				} else {
					return "Wygenerowano infografike";
				}
			}
			case 'ARTIFACTS_GENERATED': {
				if(this.eventData) {
					return "Wygenerowano raport,infografikę oraz zaszyfrowane dane z lab. w wersji: " + this.eventData.version  + ' ' + this.eventData.moduleList;;
				} else {
					return "Wygenerowano raport,infografikę oraz zaszyfrowane dane z lab.";
				}
			}
			case 'ENABLE_UPDATE': {return "Włączono dostęp do aktualizacji";}
			case 'DISABLE_UPDATE': {return "Wyłączono dostęp do aktualizacji";}
			case 'INFO_PRINTED': {return "Wydrukowano infografikę";}
			case 'REPORT_PRINTED': {
				if(this.eventData) {
					return "Wydrukowano raport w wersji: " + this.eventData.version + ' ' + this.eventData.moduleList;
				} else {
					return "Wydrukowano raport";
				}
			}
			case 'ARTIFACT_DOWNLOADED': {return "Użytkownik pobrał: [" + this.eventData.type + "]";}
			case 'MODULE_ADDED' : {return "dodano moduły: " + this.eventData.modules}
			default : return '<' + this.type+ '>';

		}
	}
}


export class OrderSummary{
	type:string;
	count:number;


	
	translate():string {
		
		switch(this.type) {
			case 'RESERVED_TO_USER': {return "Przypisany użyszkodnik :";}
			case 'EXAMPLE_RECEIVE': {return "Otrzymano próbkę śliny od użytkownika :";}
			case 'EXAMPLE_SEND_TO_LAB': {return "Wysłano ślinę do laboratorium :";}
			case 'RESULT_RECEIVE_FROM_LAB': {return "Otrzymano plik z laboratorium :";}
			case 'ARTIFACTS_GENERATED': {return "Wygenerowano: ";}
			default : return '<' + this.type+ '>';

		}
	}
}