import { Component, OnInit, Inject } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { OrderItemsVersion,VersionItem } from '../../model/order-items-version.ui'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { SnackBarHelper } from '../../components/helpers/snack-bar-helper';

@Component({
  selector: 'app-order-update',
  templateUrl: './order-update.component.html',
  styleUrls: ['./order-update.component.scss']
})
export class OrderUpdateComponent implements OnInit {

  public orderCode:string;
  public isLoaded:boolean=false;
  private _includeReport:boolean=true;
  private _includeInfographic:boolean=true;
  public disableInfographic:boolean = false;
  private _includeRawData:boolean=true;
  public oiv:OrderItemsVersion;
  public selectedVersion = {report: new VersionItem("0.0"),infographic: new VersionItem("0.0") ,rawData: new VersionItem("0.0") }

  public loadingmsg:string;

  constructor(private service:OrderService, public dialogRef: MatDialogRef<OrderUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private snackBar:SnackBarHelper) {
//  	console.log(data.orderCode);
  this.orderCode=data.order.barcode_value;
  this.loadingmsg = "Pobieranie danych próbki " + this.orderCode; 
   }

  ngOnInit() {
  	this.loadingmsg = "Pobieranie danych próbki " + this.orderCode; 
	this.isLoaded = false;
  	this.service.orderItemsVersion(this.orderCode).subscribe(t => {
  		this.oiv = t.result;
  		let selected = this.oiv.availableVersions[this.oiv.availableVersions.length-1];

		  this.selectedVersion.report = selected;
		  console.log(this.oiv);
		if(this.oiv.infographic.notEqual(new VersionItem("0.0"))) {
		  this.selectedVersion.infographic = selected;
		} else {
			console.log("Disable infographic");
			this.selectedVersion.infographic = selected;
			this.disableInfographic = true;
		}
  		this.selectedVersion.rawData = selected;
  		this.isLoaded = true;
  		
  		}
  	)
  }

	versionSelectChange(version:VersionItem,type:string) {
		let inc = this['include'+ type[0].toUpperCase() + type.slice(1)];
		if(inc && version.compareTo(this.oiv[type]) >=0) {
			this.selectedVersion[type] = version;
		}
	}


	get includeReport() {
		return this._includeReport;
	}

	set includeReport(i:boolean) {
		this._includeReport = i;
		if(!i) {
			this.selectedVersion['report'] = this.oiv.report;
		}
	}

	get includeInfographic() {
		return this._includeInfographic;
	}

	set includeInfographic(i:boolean) {
		this._includeInfographic = i;
		if(!i) {
			this.selectedVersion['infographic'] = this.oiv.infographic;
		}

	}

	get includeRawData() {
		return this._includeRawData;
	}

	set includeRawData(i:boolean) {
		this._includeRawData = i;

		if(!i) {
			this.selectedVersion['rawData'] = this.oiv.rawData;
		}
	}

	isSafeOperation() {
		return this.all() && this.selectedVersion.report.equal(this.selectedVersion.infographic) && this.selectedVersion.report.equal(this.selectedVersion.rawData);
	}

	all() {
		return this._includeReport && this._includeRawData && this._includeInfographic
	}

	doNothing() {
		let disable = (!this._includeReport ||  this.oiv.report.compareTo(this.selectedVersion.report) >0) &&
		(!this._includeRawData || this.oiv.rawData.compareTo(this.selectedVersion.rawData) > 0) &&
		(!this._includeInfographic || this.oiv.infographic.compareTo(this.selectedVersion.infographic) >0);
		return disable;
	}

	reportTouched() {
		return this._includeReport && this.oiv.report.notEqual(this.selectedVersion.report);
	}

	infographicTouched() {
		return this._includeInfographic && this.oiv.infographic.notEqual(this.selectedVersion.infographic);
	}

	rawDataTouched() {
		return this._includeRawData && this.oiv.rawData.notEqual(this.selectedVersion.rawData);
	}

	save() {
		this.loadingmsg = "Zapisuje ustawienia";
		this.isLoaded = false;
		let updateItem = {};
		updateItem['report'] = this._includeReport ? this.selectedVersion.report.value() : null;
		updateItem['infographic'] = this._includeInfographic ? this.selectedVersion.infographic.value() : null;
		updateItem['rawData'] = this._includeRawData ? this.selectedVersion.rawData.value() : null;
		console.log(updateItem);
		this.service.updateVersionItem(this.orderCode,updateItem).subscribe(t => {
			if(t.success) {
				this.snackBar.openSnackBar("Udało się - zamowienie " + this.orderCode + " zostanie zaktualizowane w ciagu kilku minut");
				this.dialogRef.close();
			} else {
				this.snackBar.openSnackBarEntity(t);
				this.dialogRef.close();			}
		},error => {this.snackBar.openSnackBarError(error);
				this.dialogRef.close();})
	}

	close() {
		this.snackBar.openSnackBar("Zamowienie nie zostalo zmodyfikowane");
		this.dialogRef.close();

	}
}
