import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SectionService } from '../../services/section.service';
import { UIService } from '../../services/ui.service'
import { GeneSectionFormBuilderService } from '../../services/gene-section-form-builder.service'
import { GeneSection } from '../../model/section/version';
import { ResponseEntity} from '../../model/response';
import { environment } from '../../../environments/environment';
import { Module } from '../../model/module';
import { ModuleService } from '../../services/module-service';



@Component({
  selector: 'gene-section-form',
  templateUrl: './gene-section-form.component.html',
  styleUrls: ['./gene-section-form.component.scss']
})
export class GeneSectionFormComponent implements OnInit {

	id: number;
	actionType: string;
	parentType: string;
  parentId: number;
	private sub: any;
	section: GeneSection;
  sectionForm: FormGroup;
  errors: any;
  sliders: any[];
  backgrounds: string[];
  progress: boolean;
  public moduleList: Module[];


  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private moduleService: ModuleService, public snackBar: MatSnackBar, private uiService: UIService, private sectionService: SectionService, private router: Router, private fb: GeneSectionFormBuilderService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) { 
    this.moduleService.getModulesAsObservable().subscribe(t => this.moduleList = t);
    this.progress = true;
    this.sectionForm = this.fb.createForm();
    this.section = new GeneSection();
    this.uiService.getAllBground().subscribe(data => {
      if (data.success) {
        this.backgrounds = data.result as string[]; 
        this.backgrounds.forEach(value =>
            {
              iconRegistry
              .addSvgIcon(value,
            sanitizer.bypassSecurityTrustResourceUrl(uiService.createBgroundIconLink(value)));
            }
          );
      }
    });
  }

  moduleCompare(o1: any, o2: any) {
    return o1.id == o2.id
}

 	ngOnInit() {
    this.actionType = this.route.snapshot.data['actionType'];
    this.parentType = this.route.snapshot.data['parentType'];
    this.uiService.getAllSliders().subscribe(data => {
      if (data.success) {
        this.sliders = data.result;
      }
    });

   if (this.actionType != 'new') {  
    this.sub = this.route.params.subscribe(params => {
        this.id = +params['id'];
       		    this.sectionService.getSection(this.id).subscribe(data => {
       						if (data.result.id != this.id) {
       							throw "Wynik z servera nie zgadza się z danymi";
       						}
                  this.section = data.result as GeneSection;
                  this.fb.populateForm(this.section, this.sectionForm);
                  this.progress = false;
                  
       				});
   		    
    });
  } else {
    this.sub = this.route.params.subscribe(params => {
      this.parentId = +params['parentId'];
    });
  }

  this.onTitleChange();
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadBackground(value: string) {
    if (value.length > 4) {

    }
  }

  onTitleChange() {
  this.sectionForm.get('title').valueChanges.subscribe(val => {
    if (this.actionType == 'new') {
      if (val) {
      this.section.name = val.default;
      }
    }});
  }

  createLink2(value: string) {
    let bgroundUrl = environment.apiUrl + '/public/ui/bgrounds/' + value;
    return bgroundUrl;
  }

  createLink(value: string) {
    if (this.sliders){
    for (let data of this.sliders)
    {
      if (data.first == value) {
        return data.second;
      }
    }}
    return null;
  }

createLabelForSnpValue(subsection: FormGroup) {
  let label: string = "Podaj wartość (";
  (subsection.controls['snps'] as FormArray).controls.forEach((c, index, array) => {
    label = label + (c as FormGroup).controls['snpValue'].value;
    if (index != array.length - 1) {
     label = label + '+'; 
    }
    
  });

  label = label + ")";
  return label;
}

  addSubSectionTab() {
    this.fb.addSubSection(this.sectionForm);
  }

  closeSubSectionTab(index: number) {
    this.fb.removeSubSection(this.sectionForm, index);
     
  }

  addVariantTab(subsection: FormGroup) {
    this.fb.addVariantTab(subsection);
  }

  closeVariantTab(index: number, subsection: FormGroup) {
    this.fb.removeVariantTab(subsection, index);
     
  }

  addSnpsTab(subsection: FormGroup) {
    this.fb.addSnp(subsection);
  }


  closeSnpsValueTab(index: number, variant: FormGroup) {
   this.fb.removeSnpValue(variant, index);
  }

  addSnpsValueTab(variant: FormGroup) {
    this.fb.addSnpValue(variant);
  }


  closeSnpsTab(index: number, subsection: FormGroup) {
   this.fb.removeSnp(subsection, index);
  }


get subsectionForms() {
  return <FormArray> this.sectionForm.controls['subsections'];
}

get actionlabel() {
  	if (this.actionType == 'new') return 'Nowa sekcja z genami';
	if (this.actionType == 'edit') return 'Edycja sekcji z genami';
  	if (this.actionType == 'view') return 'Podgląd sekcji z genami';
  }

  get readonly() {
  	return this.actionType == 'view';
  }

   back() {
    let url: string;
    if (this.actionType == 'new') {
      if (this.parentType == 'section') {
        
        url = '/app/paragraph/' + this.parentId  + '/view';
      } else {
        
        url =  '/app/version/' + this.parentId;
      }

    } else {
      if (this.section.parentId) {
        
        url = '/app/paragraph/' + this.section.parentId  + '/view';
      } else {
        url = '/app/version/' + this.section.versionId;
      }
    }
    this.router.navigate([url]);
    
  }


 onReturn(data: ResponseEntity) {
    this.progress = false;
    if (data.success == true) {
       this.snackBar.open('OK', '', {duration: 3000});
       this.router.navigate(['/app/section/' + data.result.id + '/view']);

      } else if (data.success == false && data.errors) {
          this.errors = data.errors;
      } else {
          throw data.errors;
          
      }

  }


 onSubmit() {
     this.progress = true;
    if (this.actionType == 'new') {
      this.sectionService.addGeneSection(this.fb.getGeneSection(this.sectionForm, this.section), this.parentId, this.parentType).subscribe(data => this.onReturn(data));
    } else if (this.actionType == 'edit') {
      this.sectionService.updateGeneSection(this.fb.getGeneSection(this.sectionForm, this.section)).subscribe(data => this.onReturn(data));
    }
  }

}
