export class OrderItemsVersion {

	public report:VersionItem;
	public infographic:VersionItem;
	public rawData:VersionItem;
	public availableVersions:VersionItem[];

}


export class VersionItem {
	public major:number;
	public minor:number;


	constructor(version:string) {
		if(version) {

		this.major = +version.split('.')[0];
		this.minor = +version.split('.')[1];
		} else {
			this.major = 0;
			this.minor = 0;
		}
	}

	value() {
		return this.major + '.' + this.minor
	}

	compareTo(version:VersionItem) {

		if(this.major === version.major) {
			return this.minor - version.minor;
		}
		return  this.major - version.major;
	}


	equal(version:VersionItem) {
	 return this.major === version.major && this.minor === version.minor;
	}

	notEqual(version:VersionItem) {
	 return !this.equal(version);
	}
}
