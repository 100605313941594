<div fxLayoutAlign="space-between">
  <button mat-button matSuffix mat-icon-button aria-label="Back"  (click)="back()">
       <mat-icon>undo</mat-icon>
  </button>
  <h3>{{actionlabel}}</h3>
   <button mat-button matSuffix mat-icon-button aria-label="Edit" [disabled]="!readonly" [routerLink]="['/app/section',section?.id,'edit']">
        <mat-icon>edit</mat-icon>
    </button>

</div>


<form [formGroup]="sectionForm">    
<mat-card>
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
     <mat-slide-toggle
          color="accent"
          [disabled]="readonly" formControlName="raportEnabled">
          {{sectionForm.controls['raportEnabled'].value ? 'Sekcja widoczna   ' : 'Sekcja niewidoczna'}}
      </mat-slide-toggle>
    <span >{{section?.name || 'Nowa sekcja'}}</span>
    <ogen-download  [isButtonTemplate]="false" [disabled] = "!readonly" matTooltip="Zostanie wygenerowany raport zawierający tylko jeden rozdział (zawierający tą sekcję)" [section]="section?.id" url="/api/report/dummy"></ogen-download>
  </mat-toolbar>
  <mat-progress-bar *ngIf="progress" color="accent" mode="query"></mat-progress-bar>
  <mat-card-content fxLayout="column"  fxLayoutAlign="center">

    <div fxLayout="row" fxLayoutAlign="space-around"  fxLayoutGap="20px" fxLayout.sm="column">
      <mat-form-field fxFlex="50" fxFlex.sm="100" color="{{sectionForm.controls['title'].errors && !sectionForm.controls['title'].pristine ? 'warn' : 'primary'}}">
          <app-language-text [disabled]="readonly" formControlName="title" placeholder="Tytuł"></app-language-text>  
         <mat-error *ngIf="sectionForm.controls['title'].invalid">Zla wartosc</mat-error>
      </mat-form-field>

      <!--mat-form-field fxFlex="15" fxFlex.sm="100" >
          <mat-label>Moduły</mat-label>
          <mat-select  [disabled] = "readonly" placeholder="Moduły" [compareWith]="moduleCompare"  formControlName="modules" multiple>
              <mat-option *ngFor="let obj of moduleList" [value]="obj">{{obj.name}}</mat-option>
          </mat-select>
      </mat-form-field-->

      <mat-form-field fxFlex="24" fxFlex.sm="100" color="primary">
        <mat-select placeholder="Obrazek tła" formControlName="backgroundImage" [disabled]="readonly">
           <mat-select-trigger>
            <img *ngIf="sectionForm.controls['backgroundImage'].value" [src] ="createLink2(sectionForm.controls['backgroundImage'].value)" width="9%" height="9%" /> {{ sectionForm.controls['backgroundImage'].value }}
          </mat-select-trigger>
        <mat-option value="">None</mat-option>
        <mat-option *ngFor="let bground of backgrounds" [value]="bground">
          <img [src]= "createLink2(bground)" width="9%" height="9%" /> {{ bground }}
        </mat-option>
      </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="24" fxFlex.sm="100" color="{{sectionForm.controls['priority'].errors && !sectionForm.controls['priority'].pristine ? 'warn' : 'primary'}}">
        <input type="number" matInput [readonly]="readonly" formControlName="priority" placeholder="Priorytet"/>
         <mat-error *ngIf="sectionForm.controls['priority'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
   </div>
  <div fxLayout="row" fxLayoutAlign="space-around"  fxLayoutGap="20px" fxLayout.sm="column">
    <mat-form-field fxFlex color="{{sectionForm.controls['labelLeft'].errors && !sectionForm.controls['labelLeft'].pristine ? 'warn' : 'primary'}}">
        <app-language-text [disabled]="readonly" formControlName="labelLeft"  placeholder="Etykieta lewa"></app-language-text>  
         <mat-error *ngIf="sectionForm.controls['labelLeft'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex color="{{sectionForm.controls['indicator'].errors && !sectionForm.controls['indicator'].pristine ? 'warn' : 'primary'}}">
      <mat-select placeholder="Suwak" *ngIf="!readonly" formControlName="indicator">
         <mat-select-trigger>
            <img *ngIf="sectionForm.controls['indicator'].value" [src] ="createLink(sectionForm.controls['indicator'].value)" width="45%" height="30%" />
          </mat-select-trigger>
        <mat-option></mat-option>
        <mat-option *ngFor="let slider of sliders" [value]="slider.first">
          <img [src]= "slider.second" width="90%" height="90%" />
        </mat-option>
      </mat-select>
      <input matInput *ngIf="readonly" formControlName="indicator" readonly="readonly" placeholder="Suwak" hidden="true" >
      <img *ngIf="readonly" [src]="createLink(sectionForm.controls['indicator'].value)" width="45%" height="30%"/>
      <mat-error *ngIf="sectionForm.controls['indicator'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex color="{{sectionForm.controls['labelRight'].errors && !sectionForm.controls['labelRight'].pristine ? 'warn' : 'primary'}}">
          <app-language-text [disabled]="readonly" formControlName="labelRight"  placeholder="Etykieta prawa"></app-language-text>  
         <mat-error *ngIf="sectionForm.controls['labelRight'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex color="{{sectionForm.controls['scale'].errors && !sectionForm.controls['scale'].pristine ? 'warn' : 'primary'}}">
        <input type="number" matInput formControlName="scale" placeholder="Skala" [readonly]="readonly"/>
         <mat-error *ngIf="sectionForm.controls['scale'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
     </div>

   <!--expanded="{{panel.expanded || readonly || (sectionForm.controls['contents'].invalid && sectionForm.controls['contents'].touched)}}" -->
   <mat-accordion >
  <mat-expansion-panel  #panel
  			hideToggle="{{readonly || sectionForm.controls['contents'].invalid && sectionForm.controls['contents'].touched}}" 
  			expanded='true' 
  			disabled="{{readonly || sectionForm.controls['contents'].invalid && sectionForm.controls['contents'].touched}}">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-error *ngIf="!readonly && sectionForm.controls['contents'].invalid">Treści stałe</mat-error>
        <div *ngIf="readonly || sectionForm.controls['contents'].valid">Treści stałe</div>
      </mat-panel-title>
      <mat-panel-description >
      	<mat-error *ngIf="!readonly && sectionForm.controls['contents'].invalid">coś tu musisz uzupełnić... zajrzyj</mat-error>
      </mat-panel-description>
    </mat-expansion-panel-header>
   <div fxLayout="column" fxLayoutAlign="space-around" fxLayout.sm="column" formGroupName="contents"  >
      <mat-form-field fxFlex color="{{sectionForm.get('contents.header').errors && !sectionForm.get('contents.header').pristine ? 'warn' : 'primary'}}">
          <app-language-text [disabled]="readonly" formControlName="header"  placeholder="Nagłówek"></app-language-text>  
         <mat-error *ngIf="sectionForm.get('contents.header').invalid">Zła wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex fxFlexOffset="5" color="{{sectionForm.get('contents.funFacts').errors && !sectionForm.get('contents.funFacts').pristine ? 'warn' : 'primary'}}">
          <app-language-text [disabled]="readonly" formControlName="funFacts"  placeholder="Czy wiesz że:"></app-language-text>  
      </mat-form-field>
      
      <mat-form-field fxFlex fxFlexOffset="5" color="{{sectionForm.get('contents.footer').errors && !sectionForm.get('contents.footer').pristine ? 'warn' : 'primary'}}">
          <app-language-text [disabled]="readonly" formControlName="footer"  placeholder="Stopka"></app-language-text>  
      </mat-form-field>
   </div>
	</mat-expansion-panel>
</mat-accordion>

  <mat-tab-group formArrayName="subsections" fxFlexOffset="5">
    <mat-tab *ngFor="let subsection of subsectionForms.controls; let subsectionIndex=index; let firstSsElem=first" [formGroupName]="subsectionIndex">
      <ng-template mat-tab-label>
        <div fxLatout="row" fxLayoutAlign="space-between center">
          <div>{{subsection.controls['name'].value || "(nowa nazwa)"}}</div>
      	   <button mat-button matSuffix mat-icon-button aria-label="Clear" color="warn"  *ngIf="!firstSsElem && !readonly"  (dblclick)="closeSubSectionTab(subsectionIndex)" style="position:relative;bottom:8pt;">
    		    <mat-icon class="size-16">clear</mat-icon>
  		    </button>
      </div>
    </ng-template>
	 <div fxLayout="column">
      <div fxLayout="row" fxFlexOffset="2"  fxLayoutGap="20px" fxLayoutAlign="space-around" fxLayout.sm="column">
		<mat-form-field fxFlex color="{{subsection.controls['name'].errors && !subsection.controls['name'].pristine ? 'warn' : 'primary'}}">
        <input type="text" matInput [readonly] = "readonly" formControlName="name" placeholder="Nazwa"/>
         <mat-error *ngIf="subsection.controls['name'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex color="{{subsection.controls['gene'].errors && !subsection.controls['gene'].pristine ? 'warn' : 'primary'}}">
        <input type="text" [readonly] = "readonly" matInput formControlName="gene" placeholder="Gen"/>
         <mat-error *ngIf="subsection.controls['gene'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      </div>
      <div fxLayout="row"  fxLayoutGap="20px" fxLayoutAlign="space-around" fxLayout.sm="column">
		<mat-form-field fxFlex="33" fxFlex.sm="100" color="{{subsection.controls['chromosome'].errors && !subsection.controls['chromosome'].pristine ? 'warn' : 'primary'}}">
        <mat-select *ngIf="!readonly" formControlName="chromosome" placeholder="Chromosome">
        	<mat-option value="1">1</mat-option>
        	<mat-option value="2">2</mat-option>
        	<mat-option value="3">3</mat-option>
        	<mat-option value="4">4</mat-option>
        	<mat-option value="5">5</mat-option>
        	<mat-option value="6">6</mat-option>
        	<mat-option value="7">7</mat-option>
        	<mat-option value="8">8</mat-option>
        	<mat-option value="9">9</mat-option>
        	<mat-option value="10">10</mat-option>
        	<mat-option value="11">11</mat-option>
        	<mat-option value="12">12</mat-option>
        	<mat-option value="13">13</mat-option>
        	<mat-option value="14">14</mat-option>
        	<mat-option value="15">15</mat-option>
        	<mat-option value="16">16</mat-option>
        	<mat-option value="17">17</mat-option>
        	<mat-option value="18">18</mat-option>
        	<mat-option value="19">19</mat-option>
        	<mat-option value="20">20</mat-option>
          <mat-option value="21">21</mat-option>
          <mat-option value="22">22</mat-option>
        	<mat-option value="X">X</mat-option>
        	<mat-option value="Y">Y</mat-option>
        	<mat-option value="MT">MT</mat-option>
        </mat-select>
        <input *ngIf="readonly"  [readonly] = "readonly" type="text" matInput formControlName="chromosome" placeholder="Chromosom"/>
         <mat-error *ngIf="subsection.controls['chromosome'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="66" fxFlex.sm="100" color="{{subsection.controls['study'].errors && !subsection.controls['study'].pristine ? 'warn' : 'primary'}}">
        <input  [readonly] = "readonly" type="text" matInput formControlName="study" placeholder="Na podstawie studiów"/>
      </mat-form-field>
      </div>

      <div formArrayName="snps" fxLayout="row" fxLayoutWrap fxLayoutGap="10px" >
      	
      	<mat-card class="smallSize" *ngFor="let snps of subsection.controls['snps'].controls; let snpsIndex=index; let lastElem=last; let firstElem=first" [formGroupName]="snpsIndex">
      		<mat-card-content >
      			<div fxLayout="column">
      				<div fxLayout="row" fxLayoutAlign="space-between center">
      					<div>{{snpsIndex +1}}</div>
      					<div>
      						<span>
      							<button mat-button matSuffix mat-icon-button aria-label="Add" color="primary" *ngIf="lastElem && !readonly"  (click)="addSnpsTab(subsection)">
    								<mat-icon class="size-16">add circle outline</mat-icon>
  								</button>
	
      						</span>
      						<span>
      							<button mat-button matSuffix mat-icon-button aria-label="Clear" color="warn"  *ngIf="!firstElem && !readonly"  (click)="closeSnpsTab(snpsIndex,subsection)">
    								<mat-icon class="size-16">clear</mat-icon>
  								</button>
      						</span>
      					</div>
      				</div>
					<div fxLayout="row" fxLayoutGap="10px">
      					<mat-form-field fxFlex="45"  color="{{snps.controls['snpValue'].errors && !snps.controls['snpValue'].pristine ? 'warn' : 'primary'}}">
        					<input type="text" [readonly] = "readonly" matInput formControlName="snpValue" placeholder="Wartość SNP"/>
         					<mat-error *ngIf="snps.controls['snpValue'].invalid">Zla wartosc</mat-error>
      					</mat-form-field>
      					<mat-form-field fxFlex="45" fxFill color="{{snps.controls['correctValues'].errors && !snps.controls['correctValues'].pristine ? 'warn' : 'primary'}}">
        					<input type="text" [readonly] = "readonly" matInput formControlName="correctValues" placeholder="Dopuszczone wartości"/>
         					<mat-error  *ngIf="snps.controls['correctValues'].invalid">Zla wartosc</mat-error>
      					</mat-form-field>
      				</div>
      			</div>
  			</mat-card-content>
		</mat-card>
		
	</div>

<mat-tab-group formArrayName="variants"  fxFlexOffset="5" backgroundColor="primary">
    <mat-tab *ngFor="let variant of subsection.controls['variants'].controls; let variantIndex=index; let firstVariantElem=first" [formGroupName]="variantIndex" >
      <ng-template mat-tab-label><div fxLatout="row" fxLayoutAlign="space-between center"><div>{{variant.controls['name'].value || "(nowa nazwa)"}}</div>
		<button mat-button matSuffix mat-icon-button aria-label="Clear" style="position:relative;bottom:8pt;"  *ngIf="!firstVariantElem && !readonly" (dblclick)="closeVariantTab(variantIndex,subsection)">
    	<mat-icon class="size-16">clear</mat-icon>
  		</button>
      </div>
  </ng-template>
     
	<div fxLayout="column">
 	<div fxLayout="row" fxFlexOffset="2" fxLayoutGap="20px" fxLayout.sm="column">
      <mat-form-field fxFlex color="{{variant.controls['name'].errors && !variant.controls['name'].pristine ? 'warn' : 'primary'}}">
        <input [readonly] = "readonly" type="text" matInput formControlName="name" placeholder="Nazwa"/>
         <mat-error *ngIf="variant.controls['name'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex color="{{variant.controls['probability'].errors && !variant.controls['probability'].pristine ? 'warn' : 'primary'}}">
        <input type="number" [readonly] = "readonly" matInput formControlName="probability" placeholder="Prawdopodobieństwo"/>
         <mat-error *ngIf="variant.controls['probability'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
   </div>
  <div fxLayout="row" fxFlexOffset="2" fxLayoutGap="20px" fxLayout.sm="column">
      <mat-form-field fxFlex color="{{variant.controls['shortDescription'].errors && !variant.controls['shortDescription'].pristine ? 'warn' : 'primary'}}">
          <app-language-text [disabled]="readonly" formControlName="shortDescription"  placeholder="Wynik skrócony do tabelki"></app-language-text>  
         <mat-error *ngIf="variant.controls['shortDescription'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex color="{{variant.controls['score'].errors && !variant.controls['score'].pristine ? 'warn' : 'primary'}}">
        <input type="number" [readonly] = "readonly" matInput formControlName="score" placeholder="Wynik"/>
         <mat-error *ngIf="variant.controls['score'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
   </div>
  <div >
  
    <mat-form-field fxFlex  style="width: 100%;" color="{{variant.controls['description'].errors && !variant.controls['description'].pristine ? 'warn' : 'primary'}}">
        <app-language-text [disabled]="readonly" formControlName="description"  placeholder="Opis"></app-language-text>  
         <mat-error *ngIf="variant.controls['description'].invalid">Zla wartosc</mat-error>
      </mat-form-field>
  
  </div>
	<div formArrayName="snpValues" fxLayout="row" fxLayoutWrap fxLayoutGap="10px" >

	<mat-card class="smallSize" *ngFor="let snpsValue of variant.controls['snpValues'].controls; let snpValueIndex=index; let lastSnpValueElem=last; let firstSnpValueElem=first" [formGroupName]="snpValueIndex">
	

						<div fxLayout="row">
							<mat-form-field fxFlex color="{{snpsValue.controls['value'].errors && !snpsValue.controls['value'].pristine ? 'warn' : 'primary'}}">
        					<input [readonly] = "readonly" type="text" matInput formControlName="value" [placeholder]="createLabelForSnpValue(subsection)"/>
         					<mat-error *ngIf="snpsValue.controls['value'].invalid">Zla wartosc</mat-error>
      						</mat-form-field>
      						<span>
      							<button mat-button matSuffix mat-icon-button aria-label="Add" color="primary" *ngIf="lastSnpValueElem && !readonly"  (click)="addSnpsValueTab(variant)">
    								<mat-icon class="size-16">add circle outline</mat-icon>
  								</button>
	
      						</span>
      						<span>
      							<button mat-button matSuffix mat-icon-button aria-label="Clear" color="warn"  *ngIf="!firstSnpValueElem && !readonly"  (click)="closeSnpsValueTab(snpValueIndex,variant)">
    								<mat-icon class="size-16">clear</mat-icon>
  								</button>
      						</span>
      					</div>
	</mat-card>
		

	</div>

	</div>
	

  	</mat-tab>
  	<mat-tab *ngIf="!readonly" disabled="disabled">
      <ng-template mat-tab-label>
      	<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="addVariantTab(subsection)">
    	<mat-icon>add circle outline</mat-icon>
  		</button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

	</div >  
 
    </mat-tab>
    <mat-tab *ngIf="!readonly" disabled="disabled">
      <ng-template mat-tab-label>
      	<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="addSubSectionTab()">
    	<mat-icon>add circle outline</mat-icon>
  		</button>
      	
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <mat-error *ngFor="let error of errors">{{error.message}}</mat-error>

  </mat-card-content>
  <mat-card-actions align="end">
    <button *ngIf="!readonly" mat-raised-button style="margin-right: 36px;margin-bottom: 16px;" [disabled]="sectionForm.invalid" (click)="onSubmit()">Zapisz</button>
  </mat-card-actions>
</mat-card>
  </form>
