/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "@angular/flex-layout/extended";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "./download-button.component";
import * as i16 from "../../services/download.service";
var styles_DownloadButtonComponent = [i0.styles];
var RenderType_DownloadButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadButtonComponent, data: {} });
export { RenderType_DownloadButtonComponent as RenderType_DownloadButtonComponent };
function View_DownloadButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "noblock mat-spinner mat-progress-spinner"], ["color", "accent"], ["diameter", "18"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "3"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], strokeWidth: [2, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = "accent"; var currVal_4 = "18"; var currVal_5 = "3"; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DownloadButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "button", [["fxFlex", "grow"], ["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 671744, null, 0, i8.DefaultFlexDirective, [i1.ElementRef, i9.StyleUtils, i9.LAYOUT_CONFIG, i8.FlexStyleBuilder, i9.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(3, 933888, null, 0, i10.DefaultClassDirective, [i1.ElementRef, i9.StyleUtils, i9.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i5.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(4, 180224, null, 0, i11.MatButton, [i1.ElementRef, i4.Platform, i12.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DownloadButtonComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.class; _ck(_v, 1, 0, currVal_2); var currVal_3 = "grow"; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.class; _ck(_v, 3, 0, currVal_4); var currVal_5 = _co.disabled; var currVal_6 = _co.color; _ck(_v, 4, 0, currVal_5, currVal_6); var currVal_8 = _co.isDownloading; _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_7 = _co.label; _ck(_v, 6, 0, currVal_7); }); }
function View_DownloadButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.label; _ck(_v, 2, 0, currVal_2); }); }
function View_DownloadButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "noblock mat-spinner mat-progress-spinner"], ["color", "accent"], ["diameter", "18"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "3"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], strokeWidth: [2, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = "accent"; var currVal_4 = "18"; var currVal_5 = "3"; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DownloadButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "white-color"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i11.MatButton, [i1.ElementRef, i4.Platform, i12.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DownloadButtonComponent_4)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DownloadButtonComponent_5)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.disabled; _ck(_v, 1, 0, currVal_2); var currVal_3 = !_co.isDownloading; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.isDownloading; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DownloadButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadButtonComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isButtonTemplate; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isButtonTemplate; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DownloadButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ogen-download", [], null, null, null, View_DownloadButtonComponent_0, RenderType_DownloadButtonComponent)), i1.ɵdid(1, 114688, null, 0, i15.DownloadButtonComponent, [i16.DownloadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadButtonComponentNgFactory = i1.ɵccf("ogen-download", i15.DownloadButtonComponent, View_DownloadButtonComponent_Host_0, { version: "version", section: "section", title: "title", isButtonTemplate: "isButtonTemplate", url: "url", color: "color", class: "class", disabled: "disabled" }, {}, []);
export { DownloadButtonComponentNgFactory as DownloadButtonComponentNgFactory };
