import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material';
import { ResponseEntity} from '../../model/response';


@Injectable()
export class SnackBarHelper {

	private config:MatSnackBarConfig;

	constructor(private snackBar: MatSnackBar) {
	  this.config = new MatSnackBarConfig();
      this.config.verticalPosition = 'bottom';
      this.config.horizontalPosition = 'center';
      this.config.duration = 10000;
      this.config.panelClass = undefined;
      	
	}




  openSnackBar(msg:string) {
      this.snackBar.open(msg, 'OK', this.config);
  }

  openSnackBarError(error:Error) {
  	console.log(error);
  	this.openSnackBar(error.message);
  }


  openSnackBarEntity(entity:ResponseEntity) {
  		if(entity.success) {
  			this.openSnackBar("Udało się :)");
  		}
  		else {
  			console.log(entity);
  			 if(entity.errors){
      		      this.openSnackBar(entity.errors[0].message);
  			 } else {
	  			this.openSnackBar("Niestety... :(");
	  		}
  		}
  }
}
