 

<mat-sidenav-container [hasBackdrop]="hasBackdrop" class="all-wrap" autosize>
    <mat-sidenav role="navigation" #start [mode]="mode" fixedInViewport="true" fixedTopGap="8" fixedBottomGap="8" [(opened)] = "opened" class="bggrey" >

    <span fxLayout="column" fxLayoutAlign="center center" class="bgprimary">
  <mat-toolbar color = "primary" >
      <img src="/assets/images/logo.svg" height="30" alt="Ogen internal">
    </mat-toolbar>
    
  
    <button mat-flat-button *hasRole="'ROLE_ORDER'" color="primary" (click)="opened = false"  [routerLinkActive]="['active']" [routerLink]="['orders']">Zamówienia</button>

    <button mat-flat-button *hasRole="'ROLLE_UPDATE'" color="primary" (click)="opened = false"  [routerLinkActive]="['active']" [routerLink]="['update']">Aktualizacje</button>
    <button mat-flat-button *hasRole="'ROLE_MODULE'" color="primary" (click)="opened = false"  [routerLinkActive]="['active']" [routerLink]="['module']">Dodanie Modulu</button>

    <button mat-flat-button *hasRole="'ROLE_REPORT'" color="primary" (click)="opened = false" [routerLinkActive]="['active']"
        [routerLink]="['versions']">Edycja raportu</button>

   <ogen-download *hasRole="'ROLE_REPORT'" isButtonTemplate="true" url="/api/report/dummy" [matTooltip]="'Loteria raportowa dla wersji: ' + version" title="Raport"></ogen-download>

   <ogen-download *hasRole="'ROLE_REPORT'" isButtonTemplate="true" title="Infografika" url="/api/infographic/dummy" matTooltip="Loteria infografikowa... Każdy szczęściu dopomoże, kazdy dzisiaj wygrać może" ></ogen-download>

   <ogen-download *hasRole="'ROLE_REPORT'" isButtonTemplate="true" matTooltip="Full Wypas Infografika" title="FWI" url="/api/infographic/full" ></ogen-download>

   <ogen-download *hasRole="'ROLE_REPORT'" isButtonTemplate="true" title="FWR" matTooltip="Full Wypas Raporty"  url="/api/report/full" ></ogen-download>

   <button *hasRole="'ROLE_ANAL'" mat-flat-button color="primary" (click)="opened = false" matTooltip="Zaczatek analityki" [routerLinkActive]="['active']"
        [routerLink]="['analitics']">Statsy</button>
      </span>
      
    </mat-sidenav>

    <mat-sidenav-content>
    
        <app-navbar *ngIf="isVisible" [isMenuBtnVisible]="!opened" (openMenu) = "opened =$event" class="fixedToolbar" ></app-navbar>
        <div #scrollContainer class="content">
          <router-outlet (deactivate)="onDeactivate($event)"></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>





