<mat-list>
  <div mat-subheader>Moduły</div>
  <mat-list-item *ngFor="let module of modules | async">
    <mat-icon mat-list-icon>folder</mat-icon>
    <div mat-line>{{module.name}}</div>
  </mat-list-item>
</mat-list>
<mat-divider></mat-divider>
<mat-form-field >
  <mat-label>Dodaj moduł</mat-label>
  <input matInput placeholder="Nazwa modułu" value="" [formControl]="moduleFormControl">
  <mat-error *ngIf="!moduleFormControl.valid">
    Wiecej niż 3 znaki
  </mat-error>
</mat-form-field>
<button mat-raised-button color="primary" [disabled]="!moduleFormControl.valid" (click) = submit() >Dodaj</button>

