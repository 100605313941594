/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./statistics.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./statistics.component";
import * as i9 from "../../services/stats.service";
import * as i10 from "../../components/helpers/snack-bar-helper";
var styles_StatisticsComponent = [i0.styles];
var RenderType_StatisticsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatisticsComponent, data: {} });
export { RenderType_StatisticsComponent as RenderType_StatisticsComponent };
function View_StatisticsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate(_v.context.$implicit); var currVal_1 = _v.context.$implicit.count; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_StatisticsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatisticsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statsLines; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StatisticsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" statistics works!\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatisticsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.orderReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Dawaj raport"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statsLines; _ck(_v, 3, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_StatisticsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-statistics", [], null, null, null, View_StatisticsComponent_0, RenderType_StatisticsComponent)), i1.ɵdid(1, 114688, null, 0, i8.StatisticsComponent, [i9.StatsService, i10.SnackBarHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatisticsComponentNgFactory = i1.ɵccf("app-statistics", i8.StatisticsComponent, View_StatisticsComponent_Host_0, {}, {}, []);
export { StatisticsComponentNgFactory as StatisticsComponentNgFactory };
