import { Component, OnInit } from '@angular/core';
import { User } from '../../model/user';
import { ResponseEntity } from '../../model/response';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router'
import { ModuleService } from '../../services/module-service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

 user: User = new User();
 badCredential = false;
 msg: string;

  constructor(private auth: AuthService,private moduleService:ModuleService,private router:Router) { }

  ngOnInit() {
  }

  onLogin() {
     this.badCredential = false;
  	this.auth.login(this.user).subscribe(data =>
  		{
          let u:User;
          u = data.body;
          this.auth.saveUser(u,data.headers.get('authorization'));
          this.moduleService.initModules();
          this.router.navigate(['']);
      }, error => {
        this.badCredential = true;
        if(error.status == 401) {
           this.msg = "Złe dane logowania";
        } else {
          this.msg = "Coś się popsuło: ";
          console.log(error);
        }
      });
  }

}
