/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-no-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-no-found.component";
var styles_PageNoFoundComponent = [i0.styles];
var RenderType_PageNoFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNoFoundComponent, data: {} });
export { RenderType_PageNoFoundComponent as RenderType_PageNoFoundComponent };
export function View_PageNoFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ej haker - ta strona nei istnieje - ogarnij si\u0119.\n"]))], null, null); }
export function View_PageNoFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-no-found", [], null, null, null, View_PageNoFoundComponent_0, RenderType_PageNoFoundComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageNoFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNoFoundComponentNgFactory = i1.ɵccf("app-page-no-found", i2.PageNoFoundComponent, View_PageNoFoundComponent_Host_0, {}, {}, []);
export { PageNoFoundComponentNgFactory as PageNoFoundComponentNgFactory };
