import { Module } from '../module';



export class Version {
	created: string;
	createdBy: string;
	id: number;
	major: number;
	minor: number;
	modified: string;
	modifiedBy: string;
	state: string;
	emailMsg: string;
	sections: ShortSection[];
}


export class ShortSection {
	type: string;
	id: number;
	name: string;
	childs?: number;
	modules: string;
}

export class TextSection {
	type: string;
	id: number;
	name: string;
	priority: number;
	title: LangText;
	content: LangText;
	parentId: number;
	versionId: number;
	backgroundImage: string;
	color: string;
	raportEnabled: boolean;
	modules: Module[];
	childrenId: ShortSection[];
}


export class GeneSection {
	type: string;
	id: number;
	name: string;
	modules: Module[];
	labelLeft: any;
	labelRight: any;
	indicator: string;
	scale: number;
	priority: number;
	backgroundImage: string;
	title: any;
	header: any;
	footer: any;
	funFacts: any;
	parentId: number;
	versionId: number;
	raportEnabled: boolean;
	subsections: SubSection[];
}

export class LangText {
	default: string;
	en: string;

	constructor(value?: LangText) {
		this.default = value.default;
		this.en = value.en;
	}
}

export class SubSection {
	name: string;
	chromosome: string;
	study: string;
	gene: string;
	variants: Variant[];
	snps: Snp[];
}

export class Snp {
	snpValue: string;
	correctValues: string;
}

export class Variant {
	name: string;
	probability: number;
	description: any;
	score: number;
	shortDescription: any;
	snpValues: string[];
}
