import { Routes } from '@angular/router';
import { LoginComponent } from './controllers/login/login.component';
import { HomeComponent } from './controllers/home/home.component';
import { VersionsComponent } from './controllers/versions/versions.component';
import { OrdersComponent } from './controllers/orders/orders.component';
import { VersionComponent } from './controllers/version/version.component';
import { TextSectionComponent } from './controllers/text-section/text-section.component';
import { GeneSectionFormComponent } from './controllers/gene-section-form/gene-section-form.component';
import { StatisticsComponent } from './controllers/statistics/statistics.component';
import { UpdateComponent } from './controllers/update/update.component';
import { NoAccessComponent } from './controllers/no-access/no-access.component';
import { PageNoFoundComponent } from './controllers/page-no-found/page-no-found.component';
//import { OrderUpdateComponent } from './controllers/order-update/order-update.component';
import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';
import { LanguageTextComponent } from './components/language-text/language-text.component';
import { ModuleComponent } from './controllers/module/module.component';
var ɵ0 = { exceptedRole: "*" }, ɵ1 = { exceptedRole: "*" }, ɵ2 = { exceptedRole: "ROLE_ORDER" }, ɵ3 = { exceptedRole: "ROLE_REPORT" }, ɵ4 = { exceptedRole: "ROLE_ANAL" }, ɵ5 = { exceptedRole: "ROLE_UPDATE" }, ɵ6 = { exceptedRole: "ROLE_MODULE" }, ɵ7 = { exceptedRole: "ROLE_REPORT" }, ɵ8 = { actionType: "edit", exceptedRole: "ROLE_REPORT" }, ɵ9 = { actionType: "view", exceptedRole: "ROLE_REPORT" }, ɵ10 = { actionType: "edit", exceptedRole: "ROLE_REPORT" }, ɵ11 = { actionType: "view", exceptedRole: "ROLE_REPORT" }, ɵ12 = { actionType: "new", parentType: "version", exceptedRole: "ROLE_REPORT" }, ɵ13 = { actionType: "new", parentType: "version", exceptedRole: "ROLE_REPORT" }, ɵ14 = { actionType: "new", parentType: "section", exceptedRole: "ROLE_REPORT" }, ɵ15 = { actionType: "new", parentType: "section", exceptedRole: "ROLE_REPORT" };
var routes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'app', component: HomeComponent, canActivate: [AuthGuard], data: ɵ0, children: [
            { path: '', redirectTo: 'orders', pathMatch: 'full' },
            { path: 'noaccess', component: NoAccessComponent, canActivate: [AuthGuard], data: ɵ1 },
            { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: ɵ2 },
            { path: 'versions', component: VersionsComponent, canActivate: [AuthGuard], data: ɵ3 },
            { path: 'analitics', component: StatisticsComponent, canActivate: [AuthGuard], data: ɵ4 },
            { path: 'update', component: UpdateComponent, canActivate: [AuthGuard], data: ɵ5 },
            { path: 'module', component: ModuleComponent, canActivate: [AuthGuard], data: ɵ6 },
            { path: 'version/:versionId', component: VersionComponent, canActivate: [AuthGuard], data: ɵ7 },
            { path: 'paragraph/:id/edit', component: TextSectionComponent, canActivate: [AuthGuard], data: ɵ8 },
            { path: 'paragraph/:id/view', component: TextSectionComponent, canActivate: [AuthGuard], data: ɵ9 },
            { path: 'section/:id/edit', component: GeneSectionFormComponent, canActivate: [AuthGuard], data: ɵ10 },
            { path: 'section/:id/view', component: GeneSectionFormComponent, canActivate: [AuthGuard], data: ɵ11 },
            { path: 'version/:parentId/paragraph', component: TextSectionComponent, canActivate: [AuthGuard], data: ɵ12 },
            { path: 'version/:parentId/section', component: GeneSectionFormComponent, canActivate: [AuthGuard], data: ɵ13 },
            { path: 'section/:parentId/paragraph', component: TextSectionComponent, canActivate: [AuthGuard], data: ɵ14 },
            { path: 'section/:parentId/section', component: GeneSectionFormComponent, canActivate: [AuthGuard], data: ɵ15 },
        ] },
    //{ path: 'test', component: OrderUpdateComponent,canActivate: [LoginGuard]},
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'test', component: LanguageTextComponent, canActivate: [LoginGuard] },
    { path: '**', component: PageNoFoundComponent, canActivate: [LoginGuard] }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
