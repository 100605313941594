import { Input,Component, OnInit } from '@angular/core';
import { DownloadService } from '../../services/download.service'

@Component({
  selector: 'ogen-download',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent implements OnInit {

  @Input() version: number;
  @Input() section: number;
  @Input() title:string;
  @Input() isButtonTemplate:boolean;
  @Input() url:string;
  @Input() color="primary";
  @Input() class:string ="";

  private _disabled:boolean = false;
  private state:string;
  private downloadContent: Blob;

  constructor(private _ds: DownloadService) {
  this.state = 'Clear'; 
}

  ngOnInit() {
  }


  get disabled() {
  	return this._disabled;
  }

@Input()
 set disabled(disabled:boolean) {
 	this._disabled = disabled;
 }

 get isDownloading() {
 	return this.state == 'Downloading';
 }

 get label() {
 	if(this.isButtonTemplate) {
 		if(this.state == 'Clear') return this.title;
 		else if (this.state == 'Downloading') return "Trwa pobieranie";
 		else return "Pobierz"
 	} else {
 		if(this.state == 'Clear') return this.title? this.title : "cloud_download";
 		else if (this.state == 'Downloading') return "cloud_done";
 		else return "cloud_done"
 	}
 }

 click() {
	if(this._disabled || this.state == 'Downloading') {
		return;
	} else if(this.state == 'Clear'){
 		this.state = 'Downloading';
 		
		this._ds.downloadUrl(this.createUrl()).subscribe( data => {
      console.log(data);
 			this.downloadContent = data;
 			this.state = 'Downloaded';
 			},error => console.log("Error downloading file!"));
 	} else {

 		this.state="Clear";
 		let url = window.URL.createObjectURL(this.downloadContent);
    	window.open(url);
    	this.downloadContent = null;
 	}

 }

 createUrl() {

    if(this.section) {
      return this.url + '/section/' + this.section;
    }

    if(this.version) {
      return this.url +'/version/' + this.version;
    }

    return this.url;
  }
 

}
