import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseEntity } from '../model/response';


@Injectable()
export class StatsService {

 private orderUrl:string;

  constructor(private _http:HttpClient) {
  	this.orderUrl = environment.apiUrl; 
	}


  getStats() {
  	return this._http.get<ResponseEntity>(this.orderUrl + '/api/stats/summary');
  }

   orderReport() {
  	return this._http.get<ResponseEntity>(this.orderUrl + '/api/stats/clients');
  }

}
