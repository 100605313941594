import { OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { VersionItem } from '../../model/order-items-version.ui';
import { MatDialogRef } from '@angular/material';
import { SnackBarHelper } from '../../components/helpers/snack-bar-helper';
var OrderUpdateComponent = /** @class */ (function () {
    function OrderUpdateComponent(service, dialogRef, data, snackBar) {
        this.service = service;
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        this.isLoaded = false;
        this._includeReport = true;
        this._includeInfographic = true;
        this.disableInfographic = false;
        this._includeRawData = true;
        this.selectedVersion = { report: new VersionItem("0.0"), infographic: new VersionItem("0.0"), rawData: new VersionItem("0.0") };
        //  	console.log(data.orderCode);
        this.orderCode = data.order.barcode_value;
        this.loadingmsg = "Pobieranie danych próbki " + this.orderCode;
    }
    OrderUpdateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingmsg = "Pobieranie danych próbki " + this.orderCode;
        this.isLoaded = false;
        this.service.orderItemsVersion(this.orderCode).subscribe(function (t) {
            _this.oiv = t.result;
            var selected = _this.oiv.availableVersions[_this.oiv.availableVersions.length - 1];
            _this.selectedVersion.report = selected;
            console.log(_this.oiv);
            if (_this.oiv.infographic.notEqual(new VersionItem("0.0"))) {
                _this.selectedVersion.infographic = selected;
            }
            else {
                console.log("Disable infographic");
                _this.selectedVersion.infographic = selected;
                _this.disableInfographic = true;
            }
            _this.selectedVersion.rawData = selected;
            _this.isLoaded = true;
        });
    };
    OrderUpdateComponent.prototype.versionSelectChange = function (version, type) {
        var inc = this['include' + type[0].toUpperCase() + type.slice(1)];
        if (inc && version.compareTo(this.oiv[type]) >= 0) {
            this.selectedVersion[type] = version;
        }
    };
    Object.defineProperty(OrderUpdateComponent.prototype, "includeReport", {
        get: function () {
            return this._includeReport;
        },
        set: function (i) {
            this._includeReport = i;
            if (!i) {
                this.selectedVersion['report'] = this.oiv.report;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderUpdateComponent.prototype, "includeInfographic", {
        get: function () {
            return this._includeInfographic;
        },
        set: function (i) {
            this._includeInfographic = i;
            if (!i) {
                this.selectedVersion['infographic'] = this.oiv.infographic;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderUpdateComponent.prototype, "includeRawData", {
        get: function () {
            return this._includeRawData;
        },
        set: function (i) {
            this._includeRawData = i;
            if (!i) {
                this.selectedVersion['rawData'] = this.oiv.rawData;
            }
        },
        enumerable: true,
        configurable: true
    });
    OrderUpdateComponent.prototype.isSafeOperation = function () {
        return this.all() && this.selectedVersion.report.equal(this.selectedVersion.infographic) && this.selectedVersion.report.equal(this.selectedVersion.rawData);
    };
    OrderUpdateComponent.prototype.all = function () {
        return this._includeReport && this._includeRawData && this._includeInfographic;
    };
    OrderUpdateComponent.prototype.doNothing = function () {
        var disable = (!this._includeReport || this.oiv.report.compareTo(this.selectedVersion.report) > 0) &&
            (!this._includeRawData || this.oiv.rawData.compareTo(this.selectedVersion.rawData) > 0) &&
            (!this._includeInfographic || this.oiv.infographic.compareTo(this.selectedVersion.infographic) > 0);
        return disable;
    };
    OrderUpdateComponent.prototype.reportTouched = function () {
        return this._includeReport && this.oiv.report.notEqual(this.selectedVersion.report);
    };
    OrderUpdateComponent.prototype.infographicTouched = function () {
        return this._includeInfographic && this.oiv.infographic.notEqual(this.selectedVersion.infographic);
    };
    OrderUpdateComponent.prototype.rawDataTouched = function () {
        return this._includeRawData && this.oiv.rawData.notEqual(this.selectedVersion.rawData);
    };
    OrderUpdateComponent.prototype.save = function () {
        var _this = this;
        this.loadingmsg = "Zapisuje ustawienia";
        this.isLoaded = false;
        var updateItem = {};
        updateItem['report'] = this._includeReport ? this.selectedVersion.report.value() : null;
        updateItem['infographic'] = this._includeInfographic ? this.selectedVersion.infographic.value() : null;
        updateItem['rawData'] = this._includeRawData ? this.selectedVersion.rawData.value() : null;
        console.log(updateItem);
        this.service.updateVersionItem(this.orderCode, updateItem).subscribe(function (t) {
            if (t.success) {
                _this.snackBar.openSnackBar("Udało się - zamowienie " + _this.orderCode + " zostanie zaktualizowane w ciagu kilku minut");
                _this.dialogRef.close();
            }
            else {
                _this.snackBar.openSnackBarEntity(t);
                _this.dialogRef.close();
            }
        }, function (error) {
            _this.snackBar.openSnackBarError(error);
            _this.dialogRef.close();
        });
    };
    OrderUpdateComponent.prototype.close = function () {
        this.snackBar.openSnackBar("Zamowienie nie zostalo zmodyfikowane");
        this.dialogRef.close();
    };
    return OrderUpdateComponent;
}());
export { OrderUpdateComponent };
