import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { SectionService } from '../../services/section.service';
import { SnackBarHelper } from '../helpers/snack-bar-helper';

@Component({
  selector: 'mail-template',
  templateUrl: './mail-template.component.html',
  styleUrls: ['./mail-template.component.scss']
})
export class MailTemplateComponent implements OnInit {

	private _msg:string;
	private _readonly:boolean;
	public mailMsg:string;
	private _version:number;

	@Output() onSaved = new EventEmitter<any>();

  constructor(private _service:SectionService,private snack:SnackBarHelper) {
  	this._readonly=true;
  }


  ngOnInit() {
  }

  get version():number {
  	return this._version;
  }

  @Input()
  set version(version:number) {
  	this._version = version;
  }

  get readonly():boolean {
  	return this._readonly;
  }

  set readonly(readonly:boolean) {
  	this._readonly = readonly;
  	this.mailMsg = this._msg;
  }

  get msg():string {
  	return this._msg;
  }

  @Input()
  set msg(msg:string) {
  	this._readonly = true;
 	this._msg = msg;
 	this.mailMsg = msg;
 }

 saveVersion() {
 	this._service.addMsg(this.mailMsg, this._version)
 	.subscribe( 
 		data => {
			this.onSaved.emit(data);
 		}, err => {
 			this.snack.openSnackBarError(err);}

 		);
 }
 

 sendMail() {
	this._service.sendMail(this.mailMsg).subscribe(data => {this.snack.openSnackBarEntity(data)}, err => {this.snack.openSnackBarError(err)});
 }

}
